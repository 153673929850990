import { useState } from 'react'
import { BsFillSunFill } from 'react-icons/bs'
import { SessionModal } from '../session/mini/SessionModal'
import { SessionButton } from '../session/mini/SessionButton'

function toggleTheme() {
    if (localStorage.theme != 'dark') {
        document.documentElement.classList.add('dark')
        localStorage.theme = 'dark'
    } else {
        document.documentElement.classList.remove('dark')
        localStorage.removeItem('theme')
    }
}

export const Menu = () => {
    const [modal, setModal] = useState(undefined)
    return (
        <div>
            <nav className="bg-primary dark:bg-dark-primary border-b border-gray-100 dark:border-dark-secondary ease-in duration-100">
                <div className="max-w-7xl mx-auto">
                    <div className="relative  flex items-center justify-between h-16">
                        <div className="flex-1 flex items-left justify-left sm:ml-5 pl-3 sm:pl-6 sm:ml-0 sm:items-stretch sm:justify-start ">
                            <div
                                className="flex-shrink-0 flex items-center"
                                onClick={() => (window.location.href = '/home')}
                            >
                                <img
                                    className="hidden dark:block h-12 w-auto cursor-pointer"
                                    src="/assets/img/delegates.png"
                                    alt="solar"
                                />
                                <img
                                    className="dark:hidden h-12 w-auto cursor-pointer"
                                    src="/assets/img/solar.png"
                                    alt="solar"
                                />
                            </div>
                            <div className="font-black text-2xl text-white mx-2"></div>
                            {/* <div className="ml-5 hidden sm:block">
                                <input
                                    type="text"
                                    className=" border border-gray-100 dark:border-dark-secondary rounded w-full py-2 px-3 text-black dark:text-white focus:bg-gray-100 bg-white placeholder:text-gray-400 focus:placeholder:text-gray-600 dark:placeholder:text-gray-600 dark:focus:placeholder:text-gray-400 dark:bg-dark-primary
                                    dark:focus:bg-dark-tertiary focus:outline-none ease-in duration-100 outline-none"
                                    placeholder="Search delegates or news..."
                                />
                            </div> */}
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <SessionButton
                                handleClick={() => setModal('login')}
                            />
                            <div className="mt-1 mx-2 relative">
                                <div>
                                    <div
                                        onClick={toggleTheme}
                                        className="dark:text-white text-1xl ease-in duration-100 hover:rotate-45 cursor-pointer"
                                    >
                                        <BsFillSunFill />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {modal && <SessionModal modalCallback={setModal} modal={modal} />}
        </div>
    )
}

export default Menu
