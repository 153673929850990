function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

export const tabClass = ({ selected }: { selected: boolean }) =>
  classNames(
    "w-fit  leading-5 py-3 px-4  font-medium text-black dark:text-white text-lg",
    "focus:outline-none focus:ring-0 ring-offset-0 ring-offset-blue-400 ring-white ring-opacity-60",
    selected
      ? "border-b-4  hover:border-b-4 border-b-greenish hover:bg-hoverish hover:dark:bg-dark-hoverish"
      : "hover:bg-hoverish hover:dark:bg-dark-hoverish border-b-4  hover:border-b-4 border-b-transparent"
  );

  export default tabClass;