import { BadMagic } from "badmagic";
import { Method } from "badmagic/dist/types";
import config from '../../config.json'
export function Docs() {
  const superheroWorkspace = {
    id: "delegates",
    name: "Delegates",
    config: {
      baseUrl: `${config.api.delegates.url}`,
    },
    routes: [
      {
        name: "Delegate",
        path: "/delegates/:username",
      },
      {
        name: "All delegates",
        path: "/delegates",
      },

      {
        name: "Forging delegates",
        path: "/delegates/forging",
      },
      {
        name: "Standby delegates",
        path: "/delegates/standby",
      },
      {
        name: "Delegate's proposal",
        path: "/delegates/:username/proposal",
      },
      {
        name: "Latest ten proposals",
        path: "/latest_proposals",
      },
      {
        name: "Delegate's contributions",
        path: "/delegates/:username/contributions",
      },
      {
        name: "Contributions categories",
        path: "/contributions",
      },
      {
        name: "All contributions",
        path: "/contributions",
      },
      {
        name: "Contribution",
        path: "/contributions/:contributionid",
      },
    ],
  };

  return (
    <BadMagic workspaces={[superheroWorkspace]} />
  );
}