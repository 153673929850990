export const HomeHeader = () => {
    return (
        <div className="p-6">
            <div className="  font-sans flex rounded ">
                <div className="sm:border border-gray-100 dark:border-dark-secondary rounded-lg py-16 justify-center items-center sm:px-16 sm:flex">
                    <img src="assets/img/delegates.png" className="hidden dark:block w-40 h-40 mx-auto sm:m-0" />
                    <img src="assets/img/solar.png" className="block dark:hidden w-40 h-40 mx-auto sm:m-0" />
                    <div className="px-4">
                        <div className="text-5xl text-black dark:text-white font-black font-montserrat">Welcome to Delegates!</div>
                        <div className="text-xl py-3">Questions? You can learn here how SXP works.</div>
                        <div className="space-y-2">
                            <div className="rounded text-white bg-greenish  p-2 font-bold mx-1 my-2 inline-block cursor-pointer hover:bg-dark-greenish ease-in duration-100">What's a delegate?</div>
                            <div className="rounded text-white bg-greenish  p-2 font-bold mx-1 my-2 inline-block cursor-pointer hover:bg-dark-greenish hover:bg-dark-greenish ease-in duration-100">How do I use the Solar wallet?</div>
                            <div className="rounded text-white bg-greenish ease-in duration-100 hover:bg-dark-greenish p-2 font-bold mx-1 my-2 inline-block cursor-pointer ease-in duration-100" >How do I stake?</div>
                            <div className="rounded text-white bg-greenish  p-2 font-bold mx-1 my-2 inline-block cursor-pointer hover:bg-dark-greenish ease-in duration-100">Where is the roadmap?</div>
                            <div className="rounded text-white bg-greenish  p-2 font-bold mx-1 my-2 inline-block cursor-pointer hover:bg-dark-greenish ease-in duration-100" >Where can I trade SXP?</div>

                            <div className="py-3">You can also read Solar Delegates new version <a href="#stay-updated" className="text-greenish hover:underline">changelog</a> and <a href="#stay-updated" className="text-greenish hover:underline">stay updated</a>.</div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
