import MDEditor from "@uiw/react-md-editor";
import React from "react";
import { useState } from "react"
import Author from "./Author";

const useIsOverflow = (ref:any, isVerticalOverflow:boolean = true) => {
  const [isOverflow, setIsOverflow] = React.useState(undefined);

  React.useLayoutEffect(() => {
    const { current } = ref;
    const { clientWidth, scrollWidth, clientHeight, scrollHeight } = current;

    const trigger = () => {
      const hasOverflow = isVerticalOverflow ? scrollHeight > clientHeight : scrollWidth > clientWidth;

      setIsOverflow(hasOverflow);

    //   if (callback) callback(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [/*callback,*/ ref, isVerticalOverflow]);

  return isOverflow;
};

export default useIsOverflow;
export const Contribution = (props: { contribution: any }) => {

    const contribution = props.contribution;
    const [modal, setModal] = useState(false);

    const ref:any = React.useRef();
    const isOverflow = useIsOverflow(ref);


    return (
        <>
        <div onClick={() => {window.location.href=`/delegates/${contribution.delegate.username}/contributions/${contribution.id}`}} className="bg-tertiary dark:bg-dark-primary cursor-pointer border-b border-gray hover:bg-hoverish  dark:hover:bg-dark-hoverish dark:border-dark-secondary px-3 py-3 ease-in duration-100 ">
            <Author contribution={contribution}/>
            <div className="ml-14">
                <span className="text-2xl ml-2">
                    {contribution.title}
                </span>

                <div className="my-4 ml-2 max-h-[150px] overflow-hidden" ref={ref}>
                <MDEditor.Markdown source={contribution.text} />
                </div>
                <div className="flex flex-wrap gap-2">
                    {contribution.categories.map((category:any) => (
                        <div className="rounded-full bg-greenish dark:bg-dark-secondary hover:bg-dark-greenish dark:hover:bg-dark-greenish text-white w-fit px-3 font-black text-sm cursor-pointer" onClick={() => window.location.href = `/contributions/${category.title}`}>#{category.title}</div>
                    ))}
                </div>
                <div className="my-5">
                {isOverflow && (<a href="#" className="text-greenish mt-5 hover:underline">
                        See more
                    </a>)}
                </div>
            </div>
        </div>
            </>
    )
}