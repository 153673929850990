import { TextSkeleton } from "./TextSkeleton";

export const DelegatesSkeleton = () => {
    return (
        <div>
            {Array(9).fill(0).map(() => (
                <div className="animate-pulse border-b border-gray-100 dark:border-dark-secondary px-3">
                    <div className="flex">
                        <div className="rounded-full h-20 w-20 bg-gray-200 dark:bg-dark-secondary mt-6"></div>
                        <TextSkeleton lines={2}/>                   
                    </div>
                </div>
            ))}
        </div>
    );
}
