import { FaDiscord, FaMediumM, FaTelegramPlane } from 'react-icons/fa'
import { FiGithub } from 'react-icons/fi'
import ContributionsFeed from '../components/contributions/ContributionsFeed'
import { HomeHeader } from '../components/home/HomeHeader'
import { LatestProposals } from '../components/home/LatestProposals'
import { VotingBanner } from '../components/home/VotingBanner'

export const Home = () => {
    return (
        <div className="max-w-screen-sm sm:max-w-5xl min-h-screen  sm:border-x border-gray-100 dark:border-dark-secondary text-black dark:text-white">
            <HomeHeader />
            <div className="text-4xl  font-sans flex">
                <div className="grow font-black text-4xl pl-8 py-8 border-b border-gray-100 dark:border-dark-secondary">
                    Latest proposals
                </div>
            </div>
            <LatestProposals />
            <VotingBanner />
            <div className="text-4xl  font-sans flex">
                <div className="grow font-black text-4xl pl-8 py-8 border-b border-gray-100 dark:border-dark-secondary">
                    Latest contributions
                </div>
            </div>
            <ContributionsFeed />
            <div className="py-10" id="stay-updated">
                <div className="text-5xl text-center font-black italic text-greenish">
                    Stay updated!
                </div>
                <div className="p-4 italic text-center">
                    Follow the latest news of Solar and join our community!
                </div>
                <div className="flex gap-4 mx-auto sm:w-fit my-8 px-4 overflow-x-scroll no-scrollbar">
                    <a
                        className="rounded-full  p-4 border border-gray-100 dark:border-dark-secondary hover:bg-hoverish dark:hover:bg-dark-hoverish cursor-pointer ease-in duration-100"
                        href="https://t.me/solar"
                        target="_blank"
                    >
                        <FaTelegramPlane className="text-4xl text-greenish" />
                    </a>
                    <a
                        className="rounded-full   p-4  border border-gray-100 dark:border-dark-secondary hover:bg-hoverish dark:hover:bg-dark-hoverish cursor-pointer ease-in duration-100"
                        href="https://discord.solar.org/"
                        target="_blank"
                    >
                        <FaDiscord className="text-4xl text-greenish" />
                    </a>
                    <a
                        className="rounded-full  p-4 border border-gray-100 dark:border-dark-secondary hover:bg-hoverish dark:hover:bg-dark-hoverish cursor-pointer ease-in duration-100"
                        href="https://github.com/Solar-Network"
                        target="_blank"
                    >
                        <FiGithub className="text-4xl text-greenish" />
                    </a>
                    <a
                        className="rounded-full   p-4 border border-gray-100 dark:border-dark-secondary hover:bg-hoverish dark:hover:bg-dark-hoverish cursor-pointer ease-in sduration-100"
                        href="https://blog.solar.org/"
                        target="_blank"
                    >
                        <FaMediumM className="text-4xl text-greenish" />
                    </a>
                </div>
            </div>
            <div className="py-4"></div>
        </div>
    )
}
