import { MdOutlineArrowBack } from "react-icons/md"

export const NotFound = () => {
    return (
        <div className="text-black dark:text-white">
            <div
                className="border-b  border-gray-100 dark:border-dark-secondary h-16 flex text-black dark:text-white sm:border-x
        sm:border-gray-100
        sm:dark:border-dark-secondary"
            >
                <div className="w-16 h-full items-center flex ">
                    <div
                        className="mx-auto h-fit w-fit rounded-full dark:hover:bg-dark-secondary ease-in duration-100 cursor-pointer p-3"
                        onClick={() => {
                            window.history.back()
                        }}
                    >
                        <MdOutlineArrowBack className="text-2xl" />
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center">
                    {' '}
                    <span className="inline-block align-middle font-black text-xl">
                        Go back
                    </span>{' '}
                </div>
                <div className="grow"></div>
            </div>
            <div
                className="
                    flex
                    w-full
                    bg-white
                    min-h-screen
                    dark:bg-dark-primary
                    sm:border-x
                    sm:border-gray-100
                    sm:dark:border-dark-secondary
                "
            >
                <div className="mx-auto pt-16 my-16">
                    <div className="flex flex-col items-center">
                        <h1 className="font-bold text-greenish text-9xl py-8">
                            :(
                        </h1>
                        <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 dark:text-white md:text-3xl">
                            <span className="text-greenish">Oops!</span> Wrong
                            page...?
                        </h6>

                        <p className="mb-8 text-center text-gray-500 dark:text-gray-300 md:text-lg">
                            The page you’re looking for doesn’t exist.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}