import { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { Store } from "react-notifications-component";
import delegates from "../../lib/api";
import notify from "../../lib/notify";

export const DeleteContributionModal = (props: { contribution: any, setShow: (show: boolean) => void, show: boolean }) => {
    const contribution = props.contribution;
    const [current_user, setCurrentUser] = useState(undefined)

        
    useEffect(() => {
        delegates
        .api()
        .getuser()
        .then((json: any) => {
            setCurrentUser(json.username)
        })
        .catch(() => {})

    }, [])

        function deleteContribution() {
            delegates.api().delete_contribution(contribution.id)
              .then(function (res:any) {
                  if (res.status == "ok") {window.location.href = '/delegates/'+contribution.delegate.username}
              })
              .catch(function (res:any) {
                props.setShow(false);
                notify.error("We couldn't remove your contribution.");
              });
          }

    return (
        <>
            <div className={`justify-center py-8 overflow-x-hidden overflow-y-auto  ${props.show ? 'fixed' : 'hidden'} inset-0 z-50 outline-none focus:outline-none dark:text-white px-3 sm:px-16 bg-primary dark:bg-dark-primary sm:rounded-lg drop-shadow-lg sm:w-[400px] h-fit m-auto`} >

                <div className="">
                    <span className="text-2xl">
                        Are you sure?
                    </span>
                    {/* <div className="my-5"><Author name="geops" /></div> */}
                    <div className="my-4">
                        This action cannot be reversed. This contribution will be permanently deleted.
                    </div>
                    <div className="w-fit ml-auto">
                        <div className="rounded-full inline-flex border dark:border-dark-secondary  hover:dark:bg-dark-hoverish cursor-pointer p-2 dark:text-white hover:bg-dark-greenish w-fit flex  items-center h-fit space-x-2 hover:bg-hoverish hover:text-dark dark:hover:bg-dark-hoverish mx-1" onClick={()=> props.setShow(false)}>
                            <span className="font-black px-2">Better not</span>
                        </div>
                        <div className="rounded-full inline-flex border dark:border-dark-secondary  hover:dark:bg-dark-hoverish cursor-pointer p-2 dark:text-white hover:bg-dark-greenish w-fit flex  items-center h-fit space-x-2 hover:bg-red-500 hover:text-white dark:hover:bg-red-500 mx-1" onClick={()=> {if (contribution.delegate.username == current_user) {deleteContribution()}}}>
                            <span className="font-black px-2"><BsTrash className="inline-block mb-1 mr-1"/>Yes, delete it</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`opacity-50 inset-0 z-40 bg-black ${props.show ? 'fixed' : 'hidden'}`}
                onClick={() => { props.setShow(false) }}
            ></div>

        </>

    );
}
