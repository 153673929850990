import { useEffect, useState } from "react";
import delegates from "../../lib/api";
import { StakingCalculator } from "../StakingCalculator";
import { Avatar } from "./Avatar";
import { Contributes } from "./mini/Contributes";
import { EditProfileButton, EditProposalButton } from "./mini/EditProfileButton";
import { DetailedDAOShare, DetailedRewards } from "./mini/Rewards";
import { Testnet } from "./mini/Testnet";
import { VotedBy } from "./mini/VotedBy";

export const Header = (props: { delegate: any }) => {

    const [authorized, setAuthorized] = useState(undefined);
    useEffect(() => {
        delegates.api().getuser().then((user:any) => {
            if (user.username) {
                if (user.username == props.delegate.username) {
                    setAuthorized(true);
                } else {
                    setAuthorized(false);
                }
            } else {
                setAuthorized(false);
            }
        }).catch(() => setAuthorized(false))
    }, [])

    if (props.delegate && authorized != undefined) {
        return (
            <div className="sm:flex px-4 py-4">
                <Avatar delegate={props.delegate} className="w-40 h-40 aspect-square border border-gray-100 dark:border-dark-secondary mx-auto sm:m-0" />
                <div className="flex sm:p-4 grow">

                    <div className="sm:px-8 grow">
                        <div className="text-center sm:text-left sm:flex">
                            <span className="text-4xl">{props.delegate.username}</span>
                            <span className="text-4xl text-gray-400">#{props.delegate.rank}</span>
                            <div className="sm:grow"></div>
                            <div className="my-5 sm:my-0">
                                {/* {props.delegate.rank < 54 && authorized == false && <StakingCalculator delegate={props.delegate} />} */}
                                {authorized && <EditProfileButton delegate={props.delegate} />}
                                {authorized && <EditProposalButton delegate={props.delegate} />}
                            </div>

                        </div>
                        <div className="">
                            <div className="text-gray-400">{props.delegate.description ? props.delegate.description : 'No description'}</div>
                        </div>
                        <div className="py-2 sm:space-x-2 sm:flex">
                            <Contributes delegate={props.delegate} />
                            <DetailedRewards delegate={props.delegate} />
                            <DetailedDAOShare delegate={props.delegate} />
                            <VotedBy delegate={props.delegate} />
                            <Testnet delegate={props.delegate} />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}
