import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import delegates from "../../lib/api";
import { Avatar } from "../delegates/Avatar";
import { TextSkeleton } from "../skeletons/TextSkeleton";

export const LatestProposals = () => {
    const [latest_proposals, setLatestProposals] = useState([]);

    useEffect(() => {
        delegates.api().proposals().then((proposals:any) => {
            setLatestProposals(proposals);
        })
    }, []);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    if (latest_proposals) {
        return (
            <div className="py-4">
                <div className="text-4xl  font-sans">

                    <div className="px-6">
                        <Carousel responsive={responsive}>

                            {latest_proposals.map((proposal:any) => (
                                <div className="mx-2 cursor-pointer ease-in duration-100 hover:bg-hoverish rounded-lg dark:hover:bg-dark-hoverish border border-gray-100 dark:border-dark-secondary p-3 min-h-full"><div className="w-fit text-center " onClick={() => window.location.href = `/delegates/${proposal.delegate.username}`}>
                                <div className="flex"><Avatar delegate={proposal.delegate} className='w-10 h-10' />
                                    <span className="text-2xl ml-3 text-greenish">{proposal.delegate.username}</span> <span className="text-xl  mt-1 text-gray-400 dark:text-gray-600">#{proposal.delegate.rank}</span> </div></div>
                                <div className="text-base p-2">{proposal.delegate.short_proposal}</div>
                            </div>
                            ))}

                        </Carousel>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="py-4">
                <div className="text-4xl  font-sans">

                    <div className="px-6">
                        <Carousel responsive={responsive}>

                            {Array(4).fill(1).map(() => (
                                <div className="mx-2  ease-in duration-100  rounded-lg  border border-gray-100 dark:border-dark-secondary p-3 min-h-full"><div className="w-fit text-center ">
                                    <div className="flex"><Avatar delegate={{ avatar: 'd' }} className='w-10 h-10' />
                                        <div className="my-2 rounded animate-pulse bg-gray-100 dark:bg-dark-secondary mx-3 grow w-20"></div></div></div>
                                    <div className="text-base"><TextSkeleton lines={4} /></div>
                                </div>
                            ))}

                        </Carousel>
                    </div>
                </div>
            </div>
        );
    }
}
