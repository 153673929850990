import MDEditor from "@uiw/react-md-editor";
import { useEffect, useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { useParams } from "react-router-dom";
import Author from "../components/contributions/Author";
import { DeleteContributionModal } from "../components/contributions/DeleteContributionModal";
import { DeleteContributionButton, EditContributionButton } from "../components/contributions/mini/ContributionButton";
import delegates from "../lib/api";

export const Contribution = () => {
    const [contribution, setContribution] = useState(undefined);
    const [authorized, setAuthorized] = useState(undefined);

    const params = useParams();
    useEffect(() => {
        delegates.api().contribution(params.id).then((contribution:any) => {
            console.log(contribution);
            setContribution(contribution);
            
                delegates.api().getuser().then((user:any) => {
                    if (user.username) {
                        if (user.username == contribution.delegate.username) {
                            setAuthorized(true);
                        }
                    }
                })
            
        })
    }, [])

    if (contribution) {
        return (
            <>
            <div className="border-x border-gray-100 dark:border-dark-secondary w-full min-h-screen text-black dark:text-white max-w-5xl">
                <div className="border-b  border-gray-100 dark:border-dark-secondary h-16 flex ">
                    <div className="w-16 h-full items-center flex ">
                        <div className="mx-auto h-fit w-fit rounded-full hover:bg-hoverish dark:hover:bg-dark-secondary ease-in duration-100 cursor-pointer p-3" onClick={() => {window.history.back()}}><MdOutlineArrowBack className="text-2xl"/></div>
                    </div>
                    <div className="flex flex-col justify-center items-center"> <span className="inline-block align-middle font-black text-xl">Contribution</span> </div>
                    <div className="grow"></div>
                    <div className={`flex py-2 ${authorized? '' : 'hidden'}`}>
                    <EditContributionButton contribution={contribution}/>
                    <DeleteContributionButton contribution={contribution}/>
                    </div>
                </div>
                <div className="p-6">
                <Author contribution={contribution}/>
                <div className="">
                    <div className="text-4xl my-4">
                        {contribution.title}
                    </div>
    
                    <div className=" border-t border-dark-secondary py-4">
                    <MDEditor.Markdown source={contribution.text} />
                    </div>
                    <div className="flex flex-wrap gap-2 border-t border-dark-secondary py-4">
                        {contribution.categories.map((category:any) => (
                            <div className="rounded-full bg-greenish dark:bg-dark-secondary hover:bg-dark-greenish dark:hover:bg-dark-greenish text-white w-fit px-3 font-black text-sm cursor-pointer" onClick={() => window.location.href = `/contributions/${category.title}`}>#{category.title}</div>
                        ))}
                    </div>
                    <div>
                        <div className="text-3xl">
                        About {contribution.delegate.username}
                        </div>

                        {contribution.delegate.short_proposal}
                    </div>
                </div>
                </div>
            </div>
            
            </>
        );
    } else {
        return <div>Not found</div>
    }
}