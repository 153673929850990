import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import delegates from "../../lib/api";
import React from "react";
import { Wizard, useWizard } from "react-use-wizard";
import DelegateSearchBar from "./mini/DelegateSearchBar";
import config from "../../config.json";

export const SignUp = (props: {modalCallback: (state:any) => void}) => {
  const params = useParams();
  const [step1Info, setStep1Info] = useState({username: "" });
  useEffect(() => {}, []);

  const step1 = (username: string) => {
    setStep1Info({ username: username });
  };

  return (
      <div className="px-10 text-black dark:text-white">
        <Wizard>
          <Step1 handleNext={step1} />
          <Step2 delegateInfo={step1Info} />
          <Step3 delegateInfo={step1Info}/>
        </Wizard>
      </div>
  );
};
type Step1CallbackFunction = (username: string) => void;
const Step1 = (props: { handleNext: Step1CallbackFunction }) => {
  const { handleStep, previousStep, nextStep } = useWizard();

  const [delegateUsername, setDelegateUsername] = useState(undefined);
  const [usernames, setUsernames] = useState([]);
  // Attach an optional handler
  handleStep(() => {
    //alert('Going to step 2');
  });

  useEffect(()=> {
    delegates.api().notsigned().then((body:any) => {
      setUsernames(body);
    })
  },[]);

  const handleUsername = (text: string) => {
    if (usernames.indexOf(text) > -1) {
      setDelegateUsername(text);
    }
  };

  return (
    <>

      <div className="font-black text-xl sm:text-3xl flex">
        Welcome to Solar Delegates! 
      </div>
      <div className="text-gray-400 my-4">
        If you're not a delegate yet but you have a project in mind,<br/> you can register as a delegate on your Solar Wallet and your username will appear here.
      </div>
      <div className="w-full h-96">
        <div>First, select your delegate's username</div>
        
        <DelegateSearchBar
              usernames={usernames}
              handleUsername={handleUsername}
            />
      </div>
      <div className="flex">
        <div className="grow"></div>
        {delegateUsername && (
          <button
            className="rounded bg-greenish text-white p-3"
            onClick={() => {
              props.handleNext(delegateUsername);
              nextStep();
            }}
          >
            Next
          </button>
        )}
      </div>
    </>
  );
};

const Step2 = (props: {
  delegateInfo: {username: string };
}) => {
  const { handleStep, previousStep, nextStep } = useWizard();
const [verify_token, setVerifyToken] = useState(undefined)
const [error, setError] = useState(false);
  // Attach an optional handler
  handleStep(() => {
    //alert('Going to step 2');
  });

  const validateSigning = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let msg = event.currentTarget.value;

    fetch(
      config.api.delegates.url +
        "/verify",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          username:props.delegateInfo.username,
          signature: msg,
        }),
      }
    ).then(function (res) {
      res.json().then((json) => {
        if (json.status == "valid") {
          localStorage.setItem("auth", json.auth_token); 
          nextStep();
        } else {
          setError(true)
        }
      });
    });
  };

  useEffect(() => {
    fetch(
      config.api.delegates.url +
        "/generate_token",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          username:props.delegateInfo.username,
        }),
      }
    ).then(function (res) {
      res.json().then((json) => {
        if (json.status == "valid") {
          setVerifyToken(json.verify_token);
        }
      });
    });
  }, [])
  return (
    <>
      <div className="font-black text-2xl sm:text-3xl flex">
        Verify you're {props.delegateInfo.username} <div className="grow"></div>
        <span className="text-greenish text-2xl">2/3</span>{" "}
      </div>
      <div className="text-gray-400 my-4">
        Verify you are the owner of your wallet signing a message on it.
      </div>
      <div className="w-full h-96">
        <div className="mt-4 text-xl font-black">
          Copy and use this message:
        </div>
        <div className="rounded bg-secondary dark:bg-dark-secondary text-2xl mt-5 p-3 inline-block font-mono">
          {verify_token}
        </div>
        <div className="mt-4 text-xl font-black">
          ...to sign it on your wallet. Then paste the signature here:
        </div>
        <div className="mt-4">
          <textarea
            className="rounded bg-secondary dark:bg-dark-secondary h-36 w-2/3 p-3"
            onChange={validateSigning}
          ></textarea>
        </div>
        {error? <div className="text-red-400">Seems like this is not the correct signature.</div> : <div></div>}
      </div>
        {/*
          {"message":"4RLatlCJufoN","signatory":"021ea11edbe1d10af043c4bb17506d4ed623ab05eb042d6ba40c8da06e5cbd7405","signature":"8e40f0f9b07d58db7d0197fd75c3224e6a7bdc88cdf8e90db5c9944994c84d244d74ba03bf5865dc1a5a835201d9c05d6264ffa1e3c21982dcb63beedccca0be"}
        */}
      <div className="flex">
        <div className="grow"></div>
        <button className=" p-3" onClick={() => previousStep()}>
          Previous
        </button>
        
      </div>
    </>
  );
};

const Step3 = (props: {
  delegateInfo: {  username: string };
}) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dataIsSet, setDataIsSet] = useState(false);
  // Attach an optional handler
  handleStep(() => {
    //alert('Going to step 2');
  });

  const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);setDataIsSet(checkData(event.currentTarget.value,password));
  };

  const handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);setDataIsSet(checkData(email,event.currentTarget.value));
  };

  function checkData(email:string, password:string) : boolean {
    return email != "" && password != "";
  }

  const handleRegister = () => {
    fetch(
      config.api.delegates.url +
        "/register",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth")
        },
        method: "POST",
        body: JSON.stringify({
          username: props.delegateInfo.username,
          email: email,
          password: password
        }),
      }
    ).then(function (res) {
      res.json().then((json) => {
        if (json.status == "valid") {
          window.location.href = `/home`
        }
      });
    });
  };
  

  return (
    <>
      <div className="font-black text-2xl sm:text-3xl flex">
        Set up your account <div className="grow"></div>
        <span className="text-greenish text-2xl">3/3</span>{" "}
      </div>
      <div className="text-gray-400 my-4">
        Set up your account with an email, password and profile picture.
      </div>
      <div className="w-full h-96">
        <div className="mt-2 text-xl font-black">Email</div>
        <input
          type="text" value={email}
          className="p-2 bg-secondary dark:bg-dark-secondary outline-none mt-3 rounded text-xl w-1/3"
          onChange={handleEmailChange}
        />
        <div className="mt-4 text-xl font-black">Password</div>
        <input
          type="password"
          className="p-2 bg-secondary dark:bg-dark-secondary outline-none mt-3 rounded text-xl" value={password}
          onChange={handlePasswordChange}
        />
      </div>
      <div className="flex">
        <div className="grow"></div>
       {dataIsSet &&  <button className="rounded bg-greenish p-3" onClick={() => handleRegister()}>
          Next
        </button>}
      </div>
    </>
  );
};


export default SignUp;
