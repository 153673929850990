import MDEditor from '@uiw/react-md-editor'
import React, { useEffect, useState } from 'react'
import { MdOutlineArrowBack } from 'react-icons/md'
import { Store } from 'react-notifications-component'
import { Avatar } from '../components/delegates/Avatar'
import { EditEmail } from '../components/profile/EditEmail'
import { EditPassword } from '../components/profile/EditPassword'
import delegates from '../lib/api'
import notify from '../lib/notify'

export const EditProfile = () => {
    const [current_user, setCurrentUser] = useState(undefined)
    const [delegate, setDelegate] = useState(undefined)
    const [edit, setEdit] = useState(undefined);
    useEffect(() => {
        delegates
            .api()
            .getuser()
            .then((json: any) => {
                setCurrentUser(json.username)
                delegates
                    .api()
                    .find(json.username)
                    .then((del: any) => {
                        setDelegate(del)
                        setEdit({
                            description: del.description? del.description : '',
                            short_proposal:del.short_proposal? del.short_proposal : '',
                            testnet_username:del.testnet_username? del.testnet_username : '',
                            payout:del.payout != null? del.payout : 0,
                            payout_interval:del.payout_interval != null? del.payout_interval : 0,
                            dao_share:del.dao_share != null ? del.dao_share : 0,
                            avatar:del.avatar
                        })
                    })
            })
            .catch(() => {})
    }, []);

    const validateDescription = (val:string) => {if (val.length<=200) {setEdit({...edit,description:val})}}

    const validateShortProposal = (val:string) => {if (val.length<=200) {setEdit({...edit,short_proposal:val})}}

    const validatePayout = (val:string) => {if (parseInt(val) > 0 && parseInt(val) <= 100 ) {setEdit({...edit,payout:parseInt(val)})} else if (val == '') setEdit({...edit,payout:0})}

    const validatePayoutInterval = (val:string) => {if (parseInt(val) > 0) {setEdit({...edit,payout_interval:parseInt(val)})} else if (val == '') setEdit({...edit,payout_interval:0})}

    const validateDAOShare = (val:string) => {if (parseInt(val) > 0 && parseInt(val) <= 100 && parseInt(val) <= edit.payout) {setEdit({...edit,dao_share:parseInt(val)})} else if (val == '') setEdit({...edit,dao_share:0})}

    function save() {
        delegates.api().editProfile(edit,current_user).then((json:any) => {if (json.status == "ok") {window.location.href = `/delegates/${current_user}`} else {notify.error("Something went wrong :(")}}).catch(() => notify.error("Something went wrong :("))
    }

    if (current_user && delegate && edit) {
        return (
            <div className="border-x border-gray-100 dark:border-dark-secondary min-h-screen text-black dark:text-white">
                <div className="border-b  border-gray-100 dark:border-dark-secondary h-16 flex ">
                    <div className="w-16 h-full items-center flex ">
                        <div
                            className="mx-auto h-fit w-fit rounded-full hover:bg-hoverish dark:hover:bg-dark-secondary ease-in duration-100 cursor-pointer p-3"
                            onClick={() => {
                                window.history.back()
                            }}
                        >
                            <MdOutlineArrowBack className="text-2xl" />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        {' '}
                        <span className="inline-block align-middle font-black text-xl">
                            Edit profile
                        </span>{' '}
                    </div>
                    <div className="grow"></div>
                    <div className="flex py-3">
                        <div className="rounded-full  hover:bg-dark-greenish border border-gray-100 dark:border-dark-secondary transition ease-in duration-100 text-black font-black dark:text-white hover:text-white px-6 py-1 cursor-pointer mx-3" onClick={save}>
                            Save
                        </div>
                    </div>
                </div>
                <div className="py-10 px-3 sm:p-6 sm:grid grid-cols-8">
                    <div className="rounded border border-gray-100 dark:border-dark-secondary p-3 col-span-2">
                        <Avatar
                            delegate={{ avatar: edit.avatar, username: current_user }}
                            className="w-40 h-40 mx-auto"
                        />
                        <input
                            type="text"
                            name=""
                            id=""
                            value={edit.avatar}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {setEdit({...edit,avatar:e.currentTarget.value})}}
                            placeholder='URL of your avatar picture'
                            className="w-30 my-3 px-2 py-1 rounded bg-white dark:bg-dark-tertiary border border-gray-100 dark:border-dark-secondary"
                        />
                        <div className='text-gray-400 dark:text-gray-500'>Paste here your avatar picture URL.  If you leave it blank, it will load the default avatar.</div>
                    </div>
                    <div className="px-4 pt-6 sm:py-0 col-span-6">
                        <div className="relative flex-auto">
                            <div className="my-6">
                                <EditEmail />
                                <EditPassword />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-6">
                    <div className="text-2xl">Description</div>
                    <div>
                        <textarea
                            name=""
                            id=""
                            value={edit.description}
                            onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => {validateDescription(e.currentTarget.value)}}
                            className="w-full h-40 my-3 p-3 rounded bg-white dark:bg-dark-tertiary border border-gray-100 dark:border-dark-secondary"
                        />
                    </div>
                    <div className="pb-4 text-gray-400 dark:text-gray-300">Max. 200 characters. ({200-edit.short_proposal.length} left)</div>
                    <div className="flex space-x-3 py-3">
                    <div className='px-3'>
                        <div className="text-lg sm:text-2xl">Payout</div>
                        <div>
                            <input
                                name=""
                                id=""
                                value={edit.payout}
                                onChange={(e:React.ChangeEvent<HTMLInputElement>) => {validatePayout(e.currentTarget.value)}}
                                className="w-20 my-3 py-1 rounded bg-white dark:bg-dark-tertiary border border-gray-100 dark:border-dark-secondary text-center"
                            />
                            <span className="text-2xl text-gray-400 dark:text-gray-500 text-center px-2">
                                %
                            </span>
                        </div>
                    </div>
                    <div>
                    <div className="text-lg sm:text-2xl">DAO share</div>
                    <div className='px-1'>
                        <input
                            name=""
                            id=""
                            value={edit.dao_share}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {validateDAOShare(e.currentTarget.value)}}
                            className="w-20 my-3 py-1 rounded bg-white dark:bg-dark-tertiary border border-gray-100 dark:border-dark-secondary text-center"
                        />
                        <span className="text-2xl text-gray-400 dark:text-gray-500 text-center px-2">
                            %
                        </span>
                    </div>
                    </div>
                    <div className='px-3'>
                        <div className="text-lg sm:text-2xl">Interval</div>
                        <div>
                            <input
                                name=""
                                id=""
                                value={edit.payout_interval}
                                onChange={(e:React.ChangeEvent<HTMLInputElement>) => {validatePayoutInterval(e.currentTarget.value)}}
                                className="w-20 my-3 py-1 rounded bg-white dark:bg-dark-tertiary border border-gray-100 dark:border-dark-secondary text-center"
                            />
                            <span className="text-2xl text-gray-400 dark:text-gray-500 text-center px-2">
                                {' '}
                                hours
                            </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className='pl-8'>
                <div className="text-2xl">Testnet username</div>
                        <div>
                            <input
                                name=""
                                id=""
                                value={edit.testnet_username}
                                onChange={(e:React.ChangeEvent<HTMLInputElement>) => {setEdit({...edit, testnet_username:e.currentTarget.value})}}
                                placeholder="Username"
                                className=" my-3 py-1 rounded bg-white dark:bg-dark-tertiary border border-gray-100 dark:border-dark-secondary px-4"
                            />
                            
                        </div>
                        <div className="pb-4 text-gray-400 dark:text-gray-300">Leave it blank if you don't have a delegate on testnet.</div>
                </div>
                <div>
                    <div className="px-6">
                        <div className="text-2xl">Short proposal</div>
                        <div>
                            <textarea
                                name=""
                                id=""
                                value={edit.short_proposal}
                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => {validateShortProposal(e.currentTarget.value)}}
                                className="w-full h-40 my-3 p-3 rounded bg-white dark:bg-dark-tertiary border border-gray-100 dark:border-dark-secondary"
                            />
                        </div>
                        <div className="pb-4 text-gray-400 dark:text-gray-300">Max. 200 characters. ({200-edit.short_proposal.length} left)</div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="border-x border-gray-100 dark:border-dark-secondary min-h-screen text-black dark:text-white">
                <div className="border-b  border-gray-100 dark:border-dark-secondary h-16 flex ">
                    <div className="w-16 h-full items-center flex ">
                        <div
                            className="mx-auto h-fit w-fit rounded-full hover:bg-hoverish dark:hover:bg-dark-secondary ease-in duration-100 cursor-pointer p-3"
                            onClick={() => {
                                window.history.back()
                            }}
                        >
                            <MdOutlineArrowBack className="text-2xl" />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        {' '}
                        <span className="inline-block align-middle font-black text-xl">
                            Edit profile
                        </span>{' '}
                    </div>
                    <div className="grow"></div>
                    <div className="flex py-2"></div>
                </div>
                Loading
            </div>
        )
    }
}
