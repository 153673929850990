export const VotingBanner = () => {
    return (
        <div className="bg-white dark:bg-dark-tertiary py-10  px-10 border-y border-gray-100 dark:border-dark-secondary" >
                    <div className="text-greenish dark:text-white text-2xl sm:text-3xl   text-center font-black flex">
                      <div className="items-center sm:flex w-fit ">
                        <img src="/assets/img/vote.png" alt="" className="mx-auto sm:mr-5 w-32" />
                        <div>
                          <div className="py-4 sm:py-0 "> Your vote is important!</div>
                          <div>
                            <span className="text-base ">
                              <p className="text-center text-base font-normal text-gray-600 dark:text-white pt-3 font-bold">
                              <span className="font-black">Having the right forging delegates is important for the
                                network.</span> Usually each public delegate offers a
                                share in rewards, sometimes in combination with additional
                                contribution to the network or project. Voting for the
                                highest sharing delegate doesn't directly reflect in the
                                highest rewards, we call this voter dilution (more info 
                                <a
                                  href="http://thefoundry.world-domination.org/blog/index.php/2018/12/21/what-is-dilution/"
                                  className="mono-font text-greenish hover:underline"
                                  target="_blank"
                                >
                                {" here"}
                                </a>
                                ).
                              </p>
                              <p className="text-center text-gray-600 dark:text-white pt-3 font-bold hidden">
                                Sometimes voting for a delegate that shares less in
                                forging rewards might be more beneficial for you and for
                                the project. A delegate can contribute to the security of
                                the network or contribute towards marketing, community and
                                product development, which could return in short term or
                                long term a better value for the asset and project. You
                                are advised to visit{" "}
                                <a href="https://delegates.solar.org" target="_blank">
                                  this
                                </a>{" "}
                                page and DYOR on delegates before bringing out a vote.
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
    );
}
