import MDEditor from "@uiw/react-md-editor";
import { memo, useEffect, useState } from "react";
import delegates from "../../lib/api";
import { TextSkeleton } from "../skeletons/TextSkeleton";

export const Proposal = memo((props: { username: string }) => {
    const [proposal, setProposal] = useState(undefined);
    const [error, setError] = useState(false);
    useEffect(() => {
        delegates
            .api()
            .proposal(props.username)
            .then((proposal: any) => {
                if (proposal.error) {
                    setError(true);
                } else {
                    setProposal(proposal);    
                }
            }).catch(() => {
                setError(true);
            });

    }, []);
    if (error) {
        return (
            <div className=" p-3 text-center font-black text-2xl"><div className="rounded border dark:text-gray-600 text-gray-400 mx-auto py-8 border-gray-100 dark:border-dark-secondary">There is no proposal yet!</div></div>
        );
    } else {
        if (proposal) {
            return (
                <div className="p-4">
                    <MDEditor.Markdown source={proposal.text} />
                </div>
            );
        } else {
            return <TextSkeleton lines={25}/>
        }
    }
    
});
