import { BsCheckCircleFill, BsClock } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";

export const Contributes = (props: {delegate:any}) => {
    const delegate = props.delegate
    
    if (delegate.last_contribution) {
        const ledate = Date.parse(delegate.last_contribution)
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        if(+threeMonthsAgo > +ledate)
        return (
            <div className="inline-flex flex w-fit h-fit">
                      <BsClock className="text-xl font-bold text-red-500 mx-1 pt-1" />
                      <span className="text-red-500 font-bold">Not active</span>
            </div>
        );
        if(+oneMonthAgo > +ledate)
        return (
            <div className="inline-flex flex w-fit h-fit">
                      <BsCheckCircleFill className="text-xl font-bold text-yellow-400 mx-1 pt-1" />
                      <span className="text-yellow-400 font-bold">Inactive</span>
            </div>
        );
        if(+oneMonthAgo < +ledate)
           return (
            <div className="inline-flex flex w-fit h-fit">
                      <BsCheckCircleFill className="text-xl font-bold text-green-600 dark:text-green-400 mx-1 pt-1" />
                      <span className="text-green-600 dark:text-green-400 font-bold">Contributes</span>
            </div>
        );
    } else {
        return (
            <div className="inline-flex flex w-fit h-fit">
                      <RiCloseCircleFill className="text-xl font-bold text-red-500 mx-1 pt-1" />
                      <span className="text-red-500 font-bold text-sm">Contributes</span>
            </div>
        );
    }
  }
