import { Store } from "react-notifications-component";

export const notify = {
    success: (text:string)  => {
        Store.addNotification({
            title: "Success!",
            message: text,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          })
    }, error: (text:string) => {
        Store.addNotification({
            title: "Error",
            message: text,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
    }
}

export default notify;