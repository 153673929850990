import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import delegates from '../../lib/api'

export const Login = (props: {modalCallback: (state:any) => void}) => {
    const params = useParams()
    const [delgs, setDelgs] = useState({ wallet: '' })
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(undefined)

    useEffect(() => {
        delegates
            .api()
            .find(params.username)
            .then((dels: any) => {
                console.log(dels)
                setDelgs(dels)
            })
    }, [])

    const usernameChange = (event: React.FormEvent<HTMLInputElement>) => {
        setUsername(event.currentTarget.value)
    }

    const passwordChange = (event: React.FormEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value)
    }

    const handleLogin = () => {
        setError(undefined)
        delegates
            .api()
            .login(username, password)
            .then((json: any) => {
                //
                console.log(json)
                if (json.auth_token) {
                    localStorage.setItem('auth', json.auth_token)
                    window.location.reload()
                } else {
                    setError(json.status)
                }
            })
            .catch(() => {
                setError('Oops. Something went wrong')
            })
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key == 'Enter') {
            event.preventDefault()
            handleLogin()
        }
    }

    return (
        <div className="text-black dark:text-white  rounded pb-8 px-4 mb-4 flex  justify-center items-center">
            <div className="w-full">
                <div className="mx-auto w-fit">
                    <img
                        className="hidden dark:block h-12 w-auto"
                        src="/assets/img/delegates.png"
                        alt="solar"
                    />
                    <img
                        className="dark:hidden h-12 w-auto"
                        src="/assets/img/solar.png"
                        alt="solar"
                    />
                </div>
                <div className="flex mx-auto w-fit">
                    <div className="text-xl grow font-semibold  ml-2 ease-in transition-duration-100">
                        {username != '' ? (
                            <div className="text-2xl my-2 text-center">
                                Welcome back,{' '}
                                <span className="italic">{username}!</span>
                            </div>
                        ) : (
                            <div className="my-2 text-2xl">Welcome back!</div>
                        )}
                    </div>
                </div>
                <div className="rounded p-5 self-center">
                    <div className="mb-4">
                        <label className="block text-grey-darker text-sm font-bold mb-2">
                            Username
                        </label>
                        <input
                            className="border border-gray-200 dark:border-dark-secondary appearance-none  rounded w-full py-2 px-3 text-grey-darker bg-secondary dark:bg-dark-secondary"
                            id="username"
                            type="text"
                            placeholder="Your delegate username"
                            onChange={usernameChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="block text-grey-darker text-sm font-bold mb-2">
                            Password
                        </label>
                        <input
                            className="border border-gray-200 dark:border-dark-secondary  appearance-none  rounded w-full py-2 px-3 text-grey-darker bg-secondary dark:bg-dark-secondary"
                            id="password"
                            type="password"
                            placeholder="Your password"
                            onChange={passwordChange}
                            onKeyUp={handleKeyPress}
                        />
                    </div>
                    {error && (
                        <div className="mb-3">
                            <span className="text-red-500 font-bold">
                                {error}
                            </span>
                        </div>
                    )}
                    {error == undefined && <div className="py-3"></div>}
                    <a
                        href="#"
                        className="px-4 bg-greenish text-white font-black rounded py-3 inline-block align-baseline hover:bg-dark-greenish w-full text-center"
                        onClick={handleLogin}
                    >
                        Login
                    </a>
                    <div className="flex items-center justify-between">
                        <div
                            onClick={() => props.modalCallback('forgot')}
                            className="text-black dark:text-white font-bold py-2 px-4 rounded border-1 hover:underline cursor-pointer"
                        >
                            Forgot password?
                        </div>
                        <div
                            onClick={() => props.modalCallback('signup')}
                            className="text-black dark:text-white font-bold py-2 px-4 rounded border-1 hover:underline  cursor-pointer"
                        >
                            Sign Up
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
