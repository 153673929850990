import { Connection } from '@arkecosystem/client'
import config from '../config.json'

const delegates = {
    api: () => {
        const blockchain = config.api.blockchain
        const api = config.api.delegates
        var originalFetch = require('isomorphic-fetch')
        var fetch = require('fetch-retry')(originalFetch)
        return {
            core: new Connection(blockchain.url),
            all: () => {
                return fetch(api.url + `/delegates`).then(
                    (res: any) => res.json()
                )
            },
            forging: () => {
                return fetch(
                    api.url + `/delegates/forging`
                ).then((res: any) => res.json())
            },
            standby: () => {
                return fetch(
                    api.url + `/delegates/standby`
                ).then((res: any) => res.json())
            },
            find: (username: string) => {
                return fetch(
                    api.url + `/delegates/${username}`
                ).then((res: any) => res.json())
            },
            proposal: (username: string) => {
                return fetch(
                    api.url + `/delegates/${username}/proposal`
                ).then((res: any) => res.json())
            },
            delete_contribution: (id:string) => {
              return fetch(
                api.url + `/contributions/${id}`,
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("auth"),
                  },
                  method: "DELETE",
                  body: JSON.stringify({ contribution_id: id }),
                }
              ).then((res: any) => res.json())
            },
            update_proposal: (username:string, proposal:string) => {
              return fetch(api.url + `/delegates/${username}/proposal`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: localStorage.getItem('auth'),
                },
                body: JSON.stringify({
                  proposal: proposal
                }),
                method: 'POST',
            }).then((res: any) => res.json())
            },
            editProfile: (edit: {dao_share:number, payout:number, avatar:string, description:string, short_proposal:string}, username:string) => {
                return fetch(api.url + `/profile/${username}`, {
                  headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      authorization: localStorage.getItem('auth'),
                  },
                  body: JSON.stringify({
                    profile: edit
                  }),
                  method: 'POST',
              }).then((res: any) => res.json())
              },
            contributions: (
                query: { username?: string; categories?: Array<string> },
                page: number = 1,
                limit: number = 10
            ) => {
                if (query) {
                    if (query.username) {
                        return fetch(
                            api.url +
                                `/delegates/${query.username}/contributions?page=${page}&limit=${limit}`
                        ).then((res: any) => res.json())
                    }
                    if (query.categories) {
                        return fetch(
                            api.url +
                                `/contributions?categories=${query.categories.join(',')}&page=${page}&limit=${limit}`
                        ).then((res: any) => res.json())
                    }
                } else {
                    return fetch(
                        api.url +
                            `/contributions?page=${page}&limit=${limit}`
                    ).then((res: any) => res.json())
                }
            },
            contribution: (id: string) => {
                return fetch(
                    api.url + `/contributions/${id}`
                ).then((res: any) => res.json())
            },
            notsigned: () => {
                return fetch(api.url + `/notsigned`).then(
                    (res: any) => res.json()
                )
            },
            signed: () => {
                return fetch(api.url + `/signed`).then(
                    (res: any) => res.json()
                )
            },
            categories: () => {
                return fetch(api.url + `/categories`).then(
                    (res: any) => res.json()
                )
            },
            proposals: () => {
                return fetch(
                    api.url + `/latest_proposals`
                ).then((res: any) => res.json())
            },
            getuser: () => {
                return fetch(api.url + `/getuser`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('auth'),
                    },
                    method: 'POST',
                }).then((res: any) => res.json())
            },
            getemail: (username: string) => {
                return fetch(
                    api.url + `/getemail/${username}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            authorization: localStorage.getItem('auth'),
                        },
                        method: 'POST',
                    }
                ).then((res: any) => res.json())
            },
            setpassword: (
                username: string,
                oldpassword: string,
                newpassword: string
            ) => {
                return fetch(
                    api.url + `/setpassword/${username}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            authorization: localStorage.getItem('auth'),
                        },
                        body: JSON.stringify({
                            username: username,
                            oldpassword: oldpassword,
                            newpassword: newpassword,
                        }),
                        method: 'POST',
                    }
                ).then((res: any) => res.json())
            },
            setemail: (username: string, email: string) => {
                return fetch(
                    api.url + `/setemail/${username}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            authorization: localStorage.getItem('auth'),
                        },
                        body: JSON.stringify({ email: email }),
                        method: 'POST',
                    }
                ).then((res: any) => res.json())
            },
            login: (username: string, password: string) => {
                return fetch(api.url + `/login`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        username: username,
                        password: password,
                    }),
                }).then((res: any) => res.json())
            },
        }
    },
}

export default delegates
