import { useEffect, useState } from "react"
import { BsCheckCircleFill } from "react-icons/bs"
import { FaHandsHelping } from "react-icons/fa"
import { GiPayMoney } from "react-icons/gi"
import { MdOutlineHowToVote } from "react-icons/md"
import { RiCloseCircleFill } from "react-icons/ri"
import delegates from "../../../lib/api"

export const VotedBy = (props: {delegate:any}) => {

  const [votes, setVotes] = useState(undefined);

  useEffect(() => {
    delegates.api().core.api("delegates").get(props.delegate.username).then((delegate) => {
        setVotes(delegate.body.data.votes)
    })
  }, [])

  if (votes) {
      return (
          <div className="inline-block flex items-center">
            <MdOutlineHowToVote className="text-xl text-gray-400 dark:text-gray-600 mx-1 inline-flex" />
            <span className="text-gray-400 dark:text-gray-600 pb-1 font-black">Voted by {" "}
              {(votes/100000000).toLocaleString()} SXP
            </span>
          </div>
        )
  }
  else {
      return (
          <></>
        )
    } 
}
