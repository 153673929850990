import { BsCheckCircleFill } from "react-icons/bs"
import { FaHandsHelping } from "react-icons/fa"
import { GiPayMoney } from "react-icons/gi"
import { RiCloseCircleFill } from "react-icons/ri"

export const Rewards = (props: {delegate:any}) => {
    if (parseInt(props.delegate.payout) > 0) {
        return (
          <div className="inline-flex flex w-fit h-fit">
          <BsCheckCircleFill className="text-xl font-bold text-green-600 dark:text-green-400 mx-1 pt-1" />
          <span className="text-green-600 dark:text-green-400 font-bold">Rewards</span>
</div>
          )
    }
    else {
        return (
            <div className="inline-flex flex py-1 px-2 ">
              <RiCloseCircleFill className="text-xl font-bold text-red-500 mx-1 pt-1 " />
              <span className="text-red-500 font-black text-sm sm:text-base">Rewards</span>
            </div>
          )
      } 
}

export const DetailedRewards = (props: {delegate:any}) => {
  if (parseInt(props.delegate.payout) > 0) {
      return (
          <div className="inline-block flex items-center">
            <GiPayMoney className="text-xl text-white mx-1 text-green-600 dark:text-green-400 inline-flex" />
            <span className="text-green-600 dark:text-green-400 pb-1 font-black">Pays {props.delegate.payout}% every {props.delegate.payout_interval} hours
              
            </span>
          </div>
        )
  }
  else {
      return (
          <div className="inline-flex flex ">
            <RiCloseCircleFill className="text-xl font-bold text-red-500 mx-1 pt-1 " />
            <span className="text-red-500 font-black">No rewards</span>
          </div>
        )
    } 
}

export const DetailedDAOShare = (props: {delegate:any}) => {
  if (parseInt(props.delegate.dao_share) > 0) {
      return (
          <div className="inline-block flex items-center">
            <FaHandsHelping className="text-xl text-white mx-1 text-green-600 dark:text-green-400 inline-flex" />
            <span className="text-green-600 dark:text-green-400 pb-1 font-black">{props.delegate.dao_share}% to DAO
              
            </span>
          </div>
        )
  }
  else {
      return (
          <></>
        )
    } 
}
