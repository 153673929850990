import { useEffect, useState } from 'react'
import delegates from '../../lib/api'
import { DelegatesSkeleton } from '../skeletons/DelegatesSkeleton'
import { DelegateRow } from './DelegateRow'

export const DelegatesList = (props: {
    standby?: boolean
    orderByRank?: boolean
}) => {
    const [delegates_list, setDelegates] = useState(undefined)
    console.log(props.orderByRank);
    useEffect(() => {
        if (props.standby) {
            delegates
                .api()
                .standby()
                .then((list: any) => {
                    console.log(list)
                    setDelegates(list)
                })
        } else {
            delegates
                .api()
                .forging()
                .then((list: any) => {
                    console.log(list)
                    setDelegates(list)
                })
        }
    }, [])

    if (delegates_list) {
        if (delegates_list.data.length > 0) {
            return (
                <div>
                    {(props.orderByRank
                        ? delegates_list.data.sort(
                              (
                                  { rank: a }: { rank: number },
                                  { rank: b }: { rank: number }
                              ) => a-b
                          )
                        : delegates_list.data.sort(() => 0.5 - Math.random())
                    ).map((delegate: any) => (
                        <DelegateRow
                            delegate={delegate}
                            key={delegate.username}
                        />
                    ))}
                </div>
            )
        } else {
            return (
                <div className=" p-3 text-center font-black text-2xl">
                    <div className="rounded border text-gray-400 mx-auto py-8 border-gray-100 dark:border-dark-secondary">
                        There are no proposals yet!
                    </div>
                </div>
            )
        }
    } else {
        return <DelegatesSkeleton />
    }
}
