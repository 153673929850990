import { useEffect, useState } from "react";
import delegates from "../../lib/api";
import notify from "../../lib/notify";

export const EditEmail = () => {
    const [canSaveEmail, setCanSaveEmail] = useState(false);
    const [email, setEmail] = useState(undefined)
    const [emailError, setEmailError] = useState(false)
    const [current_user, setCurrentUser] = useState(undefined)

    function updateEmail(user:string) {
        delegates
          .api()
          .getemail(user)
          .then((json: any) => {
            if (json.email) {
              setEmail(json.email);
            }
          });
      }

    function validateEmail(event: React.FormEvent<HTMLInputElement>) {
        setEmail(event.currentTarget.value);
        if (event.currentTarget.value.length > 0) {
          if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              event.currentTarget.value
            )
          ) {
            setEmail(event.currentTarget.value);
            setEmailError(false);
            setCanSaveEmail(true);
          } else {
            setEmailError(true);
            setCanSaveEmail(false);
          }
        } else {
          setEmailError(true);
          setCanSaveEmail(false);
        }
      }
    
      function saveEmail() {
        delegates
          .api()
          .setemail(current_user, email)
          .then((json: any) => {
            if (json.status) {
              if (json.status == "ok") {
                notify.success("Your email has been changed!");
              } else {
                notify.error("Something went wrong :(")
              }
            } else {
                notify.error("Something went wrong :(")
            }
          })
          .catch(() => {
            notify.error("Something went wrong :(")
          });
      }

      useEffect(() => {
        delegates
            .api()
            .getuser()
            .then((json: any) => {
                setCurrentUser(json.username)
                updateEmail(json.username)
            })
            .catch(() => {})
            
    }, []);
    
    return (
        <div>
            Email
                <div>
                  <input
                    type="text"
                    maxLength={64}
                    value={email}
                    className="w-1/2 my-1 py-1 px-2 rounded bg-white dark:bg-dark-tertiary border border-gray-100 dark:border-dark-secondary"
                    onChange={validateEmail}
                  />
                </div> 
                {emailError && (
                  <span className="text-red-500">
                    You must provide a valid email address.
                  </span>
                )}
              
              {canSaveEmail && (
                <div
                  className="rounded w-fit px-2 bg-greenish hover:bg-dark-greenish transition ease-in duration-100 text-white px-3 py-2 cursor-pointer"
                  onClick={() => {
                    saveEmail();
                  }}
                >
                  Change email
                </div>
              )}
        </div>
    );
}
