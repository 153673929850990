import { useEffect, useState } from "react";
import delegates from "../../lib/api";
import { Voter } from "./Voter";
import { VotesSkeleton } from "../skeletons/VotesSkeleton";
export const Voters = (props: {delegateInfo:any, voters:any}) => {
    const delegateInfo = props.delegateInfo;
    const voters = props.voters;
    if (voters != undefined && delegateInfo != undefined) {
        return (
            <div>
                {voters.map((voter:any) => (
                    <Voter address={voter.address} balance={voter.votingFor[delegateInfo.username].votes} percentage={((voter.votingFor[delegateInfo.username].votes*100)/delegateInfo.votesReceived.votes)}/>
                ))}
            </div>
        );
    } else {
        return (
            <VotesSkeleton/>
        )
    }
}
