import { TextSkeleton } from "./TextSkeleton";

export const VotesSkeleton = () => {
    return (
        <div>
            {Array(9).fill(0).map(() => (
                <div className="animate-pulse border-b border-gray-100 dark:border-dark-secondary py-6 px-3">
                    
                    <div className="pl-9">
                            <TextSkeleton lines={3}/>
                    </div>
                </div>
            ))}
        </div>
    );
}
