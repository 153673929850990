export const TextSkeleton = (props: {lines:number}) => {
    return (
        <div className="w-full p-6">
            {
                Array(props.lines).fill(0).map(() => (
                    <div className="py-4 rounded my-2 animate-pulse bg-gray-200 dark:bg-dark-secondary"></div>
                    ))
            }
        </div>
    );
}
