import { TextSkeleton } from "./TextSkeleton";

export const ContributionsSkeleton = () => {
    return (
        <div>
            {Array(9).fill(0).map(() => (
                <div className="animate-pulse border-b border-gray-100 dark:border-dark-secondary py-6 px-3">
                    <div className="flex">
                        <div className="rounded-full p-6 bg-gray-200 dark:bg-dark-secondary"></div>
                        <div className="mt-1">
                        <div className="rounded-full w-40 ml-3 p-2 bg-gray-200 dark:bg-dark-secondary"></div>
                        <div className="rounded-full w-40 ml-3 mt-1 p-2 bg-gray-200 dark:bg-dark-secondary"></div>
                        </div>
                        
                    </div>
                    <div className="pl-9">
                            <TextSkeleton lines={3}/>
                    </div>
                </div>
            ))}
        </div>
    );
}
