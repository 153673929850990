import { Tab } from "@headlessui/react";
import { DelegatesList } from "../components/delegates/DelegatesList";
import {ImSortNumericAsc} from 'react-icons/im'
// import { DelegateFilter } from "../components/delegates/mini/Filter";
import tabClass from "../components/navigation/Tabs";
import { useState } from "react";
export const Delegates = () => {
    const [orderByRank, setOrderByRank] = useState(false);
    return (
        <div className="w-full sm:grid sm:grid-cols-6 divide-x divide-x-gray-100 dark:divide-x-dark-secondary border-x border-gray-100 min-h-screen dark:border-dark-secondary text-black dark:text-white">
            <div className="sm:col-span-6">
                <div className="my-16 ml-8  text-4xl font-black dark:text-white">
                    Delegates proposals
                </div>
                <Tab.Group>
                    <div className="w-full  border-y border-gray-100 dark:border-dark-secondary flex">
                        <Tab.List className="flex">
                            <Tab key="proposal" className={tabClass}>
                                <div className="flex space-x-3"> <span>Forging</span></div>
                            </Tab>
                            <Tab key="contributions" className={tabClass}>
                                <div className="flex space-x-3"> <span>Standby</span></div>
                            </Tab>
                        </Tab.List>
                        <div className="grow"></div>
                        <div className={`flex items-center m-2 px-3 cursor-pointer ${orderByRank? 'bg-greenish text-white' : ''} hover:bg-greenish hover:text-white text-sm rounded`} onClick={() => setOrderByRank(!orderByRank)}><ImSortNumericAsc className="inline-flex"/> <span className="ml-2">Rank</span></div>
                    </div>
                    <Tab.Panels className="">
                        <Tab.Panel>
                            <DelegatesList orderByRank={orderByRank}/>
                        </Tab.Panel>
                        <Tab.Panel>
                            <DelegatesList standby orderByRank={orderByRank}/>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
            <div className="sm:col-span-2 border-l border-gray-100 dark:border-dark-secondary hidden sm:block">
                {/* <DelegateFilter/> */}
            </div>
        </div>
    );
}
