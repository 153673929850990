import { useEffect, useState } from 'react'
import { GiFeather } from 'react-icons/gi'
import { RiLogoutBoxRLine } from 'react-icons/ri'
import delegates from '../../../lib/api'
import { Avatar } from '../../delegates/Avatar'

type ClickCallbackFunction = () => void
export const SessionButton = (props: {
    handleClick: ClickCallbackFunction
}) => {
    const [username, setUsername] = useState(undefined)
    const [delegate, setDelegate] = useState(undefined)
    useEffect(() => {
        delegates
            .api()
            .getuser()
            .then((json: any) => {
                setUsername(json.username)
                delegates.api().find(json.username).then((del:any) => {setDelegate(del)})
            })
            .catch(() => {
                setUsername(undefined)
            })
    }, [])
    return username ? (
        <div className="flex">
            <div
                className="rounded-full bg-greenish text-white hover:bg-dark-greenish ease-in duration-10 cursor-pointer font-black px-4 py-2 mx-2 flex items-center"
                onClick={() =>
                    (window.location.href = `/profile/contributions/add`)
                }
            >
                {' '}
                <GiFeather className="inline-flex mx-1" />{' '}
                <span className="hidden sm:inline-block">Add contribution</span>
            </div>
            <div className="flex px-4 mx-3 py-2 rounded-full hover:bg-greenish dark:hover:bg-dark-evenish dark:text-white hover:text-white">
                <Avatar
                    delegate={delegate? delegate : { avatar: '' } }
                    className="h-6"
                />
                <div className="ml-3">
                    <a
                        href={`/delegates/${username}`}
                        className={`text-base font-bold  `}
                    >
                        {username}
                    </a>
                </div>
            </div>
            <a
                href="#"
                onClick={() => {
                    localStorage.removeItem('auth')
                    window.location.reload()
                }}
                className=" relative  py-2 cursor-pointer hover:underline dark:hover:text-greenish content-center"
            >
                <RiLogoutBoxRLine className="mt-1 dark:text-white" />
            </a>
        </div>
    ) : (
        <a
            href="#"
            onClick={props.handleClick}
            className="ml-3 relative  font-black py-2 px-4 dark:text-white cursor-pointer hover:underline "
        >
            Sign In
        </a>
    )
}
