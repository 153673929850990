import { FaVoteYea } from "react-icons/fa";

export const Voter = (props: { address:string, balance: number, percentage:number }) => {
    return (
        <div className=" py-4 px-2 hover:bg-hoverish dark:hover:bg-dark-hoverish border-b border-gray dark:border-dark-secondary hover:cursor-pointer flex" onClick={() => window.open(`https://explorer.solar.org/wallets/${props.address}`, '_blank').focus()}>
            <div><FaVoteYea className="text-4xl m-2 text-greenish"/></div>
            <div className="grow">
            <div className="flex">
                
                <div className="text-greenish  text-sm ml-2 py-1">
                {props.address}</div>
            </div>
            <div className="ml-2">
                <div className="flex">

                    <span className="text-base dark:text-gray-300 grow">{(props.balance/100000000).toLocaleString()} SXP</span>
                    <span className="text-base">{props.percentage.toFixed(2)}%</span>
                </div>
                
            </div>
            </div>
        </div>
    );
}
