import MDEditor from '@uiw/react-md-editor'
import { useEffect, useState } from 'react'
import { MdOutlineArrowBack } from 'react-icons/md'
import delegates from '../lib/api'

export const EditProposal = () => {
    const [proposal, setProposal] = useState(undefined)
    const [current_user, setCurrentUser] = useState(undefined)

    useEffect(() => {
        delegates
            .api()
            .getuser()
            .then((json: any) => {
                setCurrentUser(json.username)
                delegates
                    .api()
                    .proposal(json.username)
                    .then((propsl: any) => {
                        if (propsl.error) {
                            setProposal(`Hello! I'm ${json.username} and this is my proposal!`)
                        }
                        if (propsl.text) {
                            setProposal(propsl.text)
                        }
                    })
                    .catch(() => {
                        
                    })
            })
            .catch(() => {})
    }, [])

    function saveProposal() {
        delegates.api().update_proposal(current_user, proposal).then(() => {
            window.location.href = '/delegates/'+current_user
        })
    }

    if (proposal && current_user) {
        return (
            <div className="text-black dark:text-white sm:border-x border-gray-100 dark:border-dark-secondary min-h-screen">
                <div className="border-b  border-gray-100 dark:border-dark-secondary h-16 flex ">
                    <div className="w-16 h-full items-center flex ">
                        <div
                            className="mx-auto h-fit w-fit rounded-full hover:bg-hoverish dark:hover:bg-dark-secondary ease-in duration-100 cursor-pointer p-3"
                            onClick={() => {
                                window.history.back()
                            }}
                        >
                            <MdOutlineArrowBack className="text-2xl" />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <span className="inline-block align-middle font-black text-xl">
                            Edit proposal
                        </span>
                    </div>
                    <div className="grow"></div>
                    <div className="flex py-3 px-4">
                        <div
                            className="rounded-full bg-greenish hover:bg-dark-greenish transition ease-in duration-100 text-white px-6 py-2 cursor-pointer"
                            onClick={saveProposal}
                        >
                            Save
                        </div>
                    </div>
                </div>
                <div className="border-b border-gray-100 dark:border-dark-secondary">
                    <MDEditor
                        value={proposal}
                        onChange={setProposal}
                        height={600}
                    />
                    <div className="py-3 sm:py-0"></div>
                </div>
            </div>
        )
    } else {
        return <div></div>
    }
}
