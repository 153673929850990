import { useState } from "react";
import { DeleteContributionModal } from "../DeleteContributionModal";

export const EditContributionButton = (props: {contribution:any}) => {
    return (
        <div className="rounded-full inline-flex border dark:border-dark-secondary  hover:dark:bg-dark-hoverish cursor-pointer p-2 dark:text-white hover:bg-dark-greenish w-fit flex  items-center h-fit space-x-2 hover:bg-hoverish dark:hover:bg-dark-hoverish mx-1" onClick={()=> window.location.href=`/profile/contributions/${props.contribution.id}/edit`}>
        
            <span className="font-black px-2">Edit</span>
        </div>
    );
}

export const DeleteContributionButton = (props: {contribution:any}) => {
    const [show, setShow] = useState(false);
    return (<>
    <div className="rounded-full inline-flex border dark:border-dark-secondary  hover:dark:bg-dark-hoverish cursor-pointer p-2 dark:text-white hover:bg-dark-greenish w-fit flex  items-center h-fit space-x-2 hover:bg-red-500 hover:text-white dark:hover:bg-red-500 mx-1" onClick={()=> setShow(true)}>
        
        <span className="font-black px-2">Delete</span>
    </div>
    <DeleteContributionModal contribution={props.contribution} setShow={setShow} show={show}/>
    </>
        
    );
}

