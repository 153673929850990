import { useEffect, useState } from "react";
import { Wizard, useWizard } from "react-use-wizard";
import DelegateSearchBar from "./mini/DelegateSearchBar";
import config from "../../config.json";
import delegates from "../../lib/api";
export const Forgot = (props: {modalCallback: (state:any) => void}) => {
    const [step1Info, setStep1Info] = useState({username: "" });
    useEffect(() => {}, []);
  
    const step1 = (username: string) => {
      setStep1Info({ username: username });
    };
  
    return (
        <div className="px-10 text-black dark:text-white">
          <Wizard>
            <Step1 handleNext={step1} />
            <Step2 delegateInfo={step1Info} />
            <Step3 delegateInfo={step1Info}/>
          </Wizard>
        </div>
    );
  };

type Step1CallbackFunction = (username: string) => void;
const Step1 = (props: { handleNext: Step1CallbackFunction }) => {
  const { handleStep, previousStep, nextStep } = useWizard();

  const [delegateUsername, setDelegateUsername] = useState(undefined);
  const [usernames, setUsernames] = useState([]);
  // Attach an optional handler
  handleStep(() => {
    //alert('Going to step 2');
  });

  useEffect(()=> {
    delegates.api().signed().then((body:any) => {
      setUsernames(body);
    })
  },[]);

  const handleUsername = (text: string) => {
    if (usernames.indexOf(text) > -1) {
      setDelegateUsername(text);
    }
  };

  return (
    <>

      <div className="font-black text-xl sm:text-3xl flex">
        Forgot your password?
      </div>
      <div className="text-gray-400 my-4">
        Don't worry! You can always recover your delegate account if you still have access to your wallet.
      </div>
      <div className="w-full h-96">
        <div>First, select your delegate's username</div>
        
        <DelegateSearchBar
              usernames={usernames}
              handleUsername={handleUsername}
            />
      </div>
      <div className="flex">
        <div className="grow"></div>
        {delegateUsername && (
          <button
            className="rounded bg-greenish text-white p-3"
            onClick={() => {
              props.handleNext(delegateUsername);
              nextStep();
            }}
          >
            Next
          </button>
        )}
      </div>
    </>
  );
};


const Step2 = (props: {
    delegateInfo: {username: string };
  }) => {
    const { handleStep, previousStep, nextStep } = useWizard();
  const [verify_token, setVerifyToken] = useState(undefined)
  const [error, setError] = useState(false);
    // Attach an optional handler
    handleStep(() => {
      //alert('Going to step 2');
    });
  
    const validateSigning = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      let msg = event.currentTarget.value;
  
      fetch(
        config.api.delegates.url +
          "/verify",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            username:props.delegateInfo.username,
            signature: msg,
          }),
        }
      ).then(function (res) {
        res.json().then((json) => {
          if (json.status == "valid") {
            localStorage.setItem("auth", json.auth_token); 
            nextStep();
          } else {
            setError(true)
          }
        });
      });
    };
  
    useEffect(() => {
      fetch(
        config.api.delegates.url +
          "/generate_token",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            username:props.delegateInfo.username,
          }),
        }
      ).then(function (res) {
        res.json().then((json) => {
          if (json.status == "valid") {
            setVerifyToken(json.verify_token);
          }
        });
      });
    }, [])
    return (
      <>
        <div className="font-black text-2xl sm:text-3xl flex">
          Verify you're {props.delegateInfo.username} <div className="grow"></div>
          <span className="text-greenish text-2xl">2/3</span>{" "}
        </div>
        <div className="text-gray-400 my-4">
          Verify you are the owner of your wallet signing a message on it.
        </div>
        <div className="w-full h-96">
          <div className="mt-4 text-xl font-black">
            Copy and use this message:
          </div>
          <div className="rounded bg-secondary dark:bg-dark-secondary text-2xl mt-5 p-3 inline-block font-mono">
            {verify_token}
          </div>
          <div className="mt-4 text-xl font-black">
            ...to sign it on your wallet. Then paste the signature here:
          </div>
          <div className="mt-4">
            <textarea
              className="rounded bg-secondary dark:bg-dark-secondary h-36 w-2/3 p-3"
              onChange={validateSigning}
            ></textarea>
          </div>
          {error? <div className="text-red-400">Seems like this is not the correct signature.</div> : <div></div>}
        </div>
        <div className="flex">
          <div className="grow"></div>
          <button className=" p-3" onClick={() => previousStep()}>
            Previous
          </button>
          
        </div>
      </>
    );
  };
  
  const Step3 = (props: {
    delegateInfo: {  username: string };
  }) => {
    const { handleStep, previousStep, nextStep } = useWizard();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [dataIsSet, setDataIsSet] = useState(false);
    // Attach an optional handler
    handleStep(() => {
      //alert('Going to step 2');
    });
  
    const handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
      setPassword(event.currentTarget.value);setDataIsSet(checkData(event.currentTarget.value));
    };
  
    function checkData(password:string) : boolean {
            return password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/) != null;
    }
  
    const handleForgot = () => {
      fetch(
        config.api.delegates.url +
          "/forgot",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("auth")
          },
          method: "POST",
          body: JSON.stringify({
            username: props.delegateInfo.username,
            password: password
          }),
        }
      ).then(function (res) {
        res.json().then((json) => {
          if (json.status == "valid") {
            window.location.href = `/home`
          }
        });
      });
    };
    
  
    return (
      <>
        <div className="font-black text-2xl sm:text-3xl flex">
          Set your new password <div className="grow"></div>
          <span className="text-greenish text-2xl">3/3</span>{" "}
        </div>
        <div className="text-gray-400 my-4">
          Set up your new password for you Delegates account. <br /> Your password must be at least 6 characters long and have one uppercase and one lowecase letter, and one number. 
        </div>
        <div className="w-full h-96">
          <div className="mt-4 text-xl font-black">Password</div>
          <input
            type="password"
            className="p-2 bg-secondary dark:bg-dark-secondary outline-none mt-3 rounded text-xl" value={password}
            onChange={handlePasswordChange}
          />
        </div>        
        <div className="flex">
          <div className="grow"></div>
         {dataIsSet &&  <button className="rounded bg-greenish p-3" onClick={() => handleForgot()}>
            Next
          </button>}
        </div>
      </>
    );
  }