import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    BrowserRouter,
    Navigate,
} from 'react-router-dom'
import EditContribution from './pages/EditContribution'
import Contributions from './pages/Contributions'
import { Delegates } from './pages/Delegates'
import { Delegate } from './pages/Delegate'
import { Contribution } from './pages/Contribution'
import { Home } from './pages/Home'
import { EditProfile } from './pages/EditProfile'
import { MdOutlineArrowBack } from 'react-icons/md'
import { EditProposal } from './pages/EditProposal'
import { Docs } from './components/developers/Docs'
import { NotFound } from './pages/NotFound'

if (localStorage.theme === 'dark') {
    document.documentElement.classList.add('dark')
}

const rootElement = document.getElementById('root')
ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/developers" element={<Docs />} />
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route path="/" element={<App />}>
                    <Route path="*" element={<NotFound />} />
                    <Route path="profile" element={<EditProfile />} />
                    <Route path="home" element={<Home />} />
                    <Route path="delegates" element={<Delegates />} />
                    <Route path="delegates/:username" element={<Delegate />} />
                    <Route path="contributions" element={<Contributions />} />
                    <Route
                        path="contributions/:category"
                        element={<Contributions />}
                    />
                    <Route
                        path="delegates/:username/contributions/:id"
                        element={<Contribution />}
                    />
                    <Route
                        path="profile/contributions/:id/edit"
                        element={<EditContribution />}
                    />
                    <Route
                        path="profile/contributions/add"
                        element={<EditContribution />}
                    />
                    <Route
                        path="profile/proposal/edit"
                        element={<EditProposal />}
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    rootElement
)
