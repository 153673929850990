import { useEffect, useState } from "react";
import delegates from "../../lib/api";
import notify from "../../lib/notify";

export const EditPassword = () => {
    const [canSavePassword, setCanSavePassword] = useState(false);
    const [oldpassword, setOldPassword] = useState(undefined);
    const [passwordError, setPasswordError] = useState(false);
    const [password, setPassword] = useState("");
    const [current_user, setCurrentUser] = useState(undefined)

    useEffect(() => {
        delegates
            .api()
            .getuser()
            .then((json: any) => {
                setCurrentUser(json.username)
            })
            .catch(() => {})
            
    }, []);

      function savePassword() {
        delegates
          .api()
          .setpassword(current_user, oldpassword, password)
          .then((json: any) => {
            if (json.status) {
              if (json.status == "ok") {
                notify.success("Your password has been changed :)");
              } else {
                notify.error("Something went wrong :(");
              }
            } else {
                notify.error("Something went wrong :(");
            }
          })
          .catch(() => {
            notify.error("Something went wrong :(");
          });
      }
    
      function validatePassword(event: React.FormEvent<HTMLInputElement>) {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    
        if (event.currentTarget.value.match(passw)) {
          setPassword(event.currentTarget.value);
          setPasswordError(false);
          setCanSavePassword(true);
        } else {
          setPasswordError(true);
          setCanSavePassword(false);
        }
      }

    return (
        <div>
          <div className="my-6">
                Current password
                <div>
                  <input
                    type="password"
                    maxLength={64}
                    className="w-1/2 my-3 py-1 rounded bg-white dark:bg-dark-tertiary border border-gray-100 dark:border-dark-secondary"
                    value={oldpassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setOldPassword(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="my-6">
                New password
                <div>
                  <input
                    type="password"
                    onChange={validatePassword}
                    maxLength={64}
                    className="w-1/2 my-3 py-1 rounded bg-white dark:bg-dark-tertiary border border-gray-100 dark:border-dark-secondary"
                  />
                </div>
                {passwordError && (
                  <span className="text-red-500 mt-3">
                    Your password must have 6 to 20 characters which contain
                    at least one numeric digit, one uppercase and one
                    lowercase letter.
                  </span>
                )}
              </div>
              {canSavePassword && (
                <div
                  className="rounded w-fit bg-greenish hover:bg-dark-greenish transition ease-in duration-100 text-white px-3 py-2 cursor-pointer"
                  onClick={() => {
                    savePassword();
                  }}
                >
                  Change password
                </div>
              )}

        </div>
    );
}
