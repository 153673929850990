import moment from "moment";
import { BsFillCircleFill } from "react-icons/bs";
import { Avatar } from "./Avatar";
import { Contributes } from "./mini/Contributes";
import { Rewards } from "./mini/Rewards";

export const DelegateRow = (props: { delegate: any }) => {
    const delegate = props.delegate;
    return (
        <div className="p-3 hover:bg-hoverish dark:hover:bg-dark-hoverish cursor-pointer border-b border-gray-100 dark:border-dark-secondary" onClick={() => window.location.href = `/delegates/${delegate.username}`}>
            <div className="flex my-1 ">
                <Avatar delegate={delegate} className="h-12" />
                <div className="ml-3">
                    <a
                        href={`/delegates/${delegate.username}`}
                        className={`text-xl font-bold hover:underline hover:cursor-pointer`}
                    >
                        {delegate.username}<span className="text-gray-400 text-base">#{delegate.rank}</span> <BsFillCircleFill className={`text-sm ${delegate.rank < 54 ? 'text-green-400' : 'text-yellow-400'} inline-block pb-1`} />
                    </a>
                    <div className="text-gray-600 dark:text-gray-400 text-sm hover:underline hover:cursor-pointer">
                        Voted by {(delegate.votes /100000000).toLocaleString()} SXP
                    </div>
                </div>
                <div className="grow"></div>
                <div className="grid justify-items-end space-y-1">
                    <Contributes delegate={delegate}/>
                    <Rewards delegate={delegate}/>
                </div>
            </div>
            {delegate.short_proposal != null &&
            <div className="rounded my-2 border border-gray-100 dark:border-dark-secondary p-6">
            {delegate.short_proposal}
            <div className="mt-1"><a href={`/delegates/${delegate.username}`} className="text-greenish hover:underline">See full proposal</a></div>
        </div>
            }
        </div>
    );
}
