import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import delegates from '../lib/api'
import React from 'react'
import MDEditor from '@uiw/react-md-editor'
import { Store } from 'react-notifications-component'
import config from '../config.json'

import { MdClose, MdOutlineArrowBack } from 'react-icons/md'
import { TextSkeleton } from '../components/skeletons/TextSkeleton'
import notify from '../lib/notify'

export const EditContribution = () => {
    const [text, setText] = React.useState('')
    const [title, setTitle] = React.useState('')

    const [titleError, setTitleError] = useState(false)
    const [textError, setTextError] = useState(false)
    const [categoryError, setCategoryError] = useState(false)
    const [contribution, setContribution] = useState(undefined)
    const [current_user, setCurrentUser] = useState(undefined)
    const [categories, setCategories] = useState(undefined)
    const [fieldCategories, setFieldCategories] = useState(undefined)
    const params = useParams()

    const [id, setId] = useState(params.id == 'add' ? undefined : params.id)
    useEffect(() => {
        delegates
            .api()
            .categories()
            .then((cats: any) => {
                setCategories(cats)
                setFieldCategories([])
            })
        if (id == undefined) {
        } else {
            delegates
                .api()
                .contribution(id)
                .then((contribution: any) => {
                    setTitle(contribution.title)
                    setText(contribution.text)
                    setContribution(contribution)
                    setFieldCategories(contribution.categories)
                })
        }
        delegates
            .api()
            .getuser()
            .then((json: any) => {
                setCurrentUser(json.username)
            })
            .catch(() => {})
    }, [])

    function validateFields() {
        if (title.length > 10) {
            setTitleError(false);
        } else {
            setTitleError(true);
        }

        if (text.length > 100) {
            setTextError(false);
        } else {
            setTextError(true);
        }

        if (fieldCategories.length > 0) {
            setCategoryError(false);
        } else {
            setCategoryError(true);
            return
        }
        
        return !(titleError || textError || categoryError);

    }

    function saveContribution() {
      if (validateFields()) {
        fetch(
            id == undefined
                ? config.api.delegates.url +
                      `/delegates/${current_user}/contributions/add`
                : config.api.delegates.url +
                      `/delegates/${current_user}/contributions/${id}`,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: localStorage.getItem('auth'),
                },
                method: 'POST',
                body: JSON.stringify({
                    contribution: {
                        title: title,
                        text: text,
                        categories: fieldCategories,
                    },
                }),
            }
        )
            .then(function (res) {
                res.json().then((json) => {
                    if (json.id) {
                        setId(json.id)
                        window.location.href = `/delegates/${current_user}/contributions/${json.id}`
                    } else {
                        notify.success("Your contribution has been updated");
                        setTitleError(false);
                        setTextError(false);
                        setCategoryError(false)
                    }
                })
            })
            .catch(function (res) {
                notify.error("We couldn't save your contribution");
            })
      }
    }

    function addOrRemoveCategory(category: { title: string; id: number }) {
        let included = fieldCategories.find(
            (cnt: { title: string }) => cnt.title == category.title
        )
        let categories = fieldCategories as Array<{ title: string; id: number }>
        if (included) {
            setFieldCategories(
                categories.filter(
                    (cnt: { title: string }) => cnt.title != category.title
                )
            )
        } else {
            setFieldCategories([...categories, category])
        }
    }

    function onTitleChange(event: React.FormEvent<HTMLInputElement>) {
        setTitle(event.currentTarget.value)
    }
    if (
        (params.id && contribution && current_user) ||
        (params.id == undefined && current_user)
    ) {
        if (
            ((contribution && contribution.delegate.username == current_user) ||
            (params.id == undefined && current_user)) && categories && fieldCategories
        ) {
            return (
                <div className=" mx-auto sm:border-x border-gray-100 dark:border-dark-secondary min-h-screen text-black dark:text-white">
                    <div className="border-b  border-gray-100 dark:border-dark-secondary h-16 flex ">
                        <div className="w-16 h-full items-center flex ">
                            <div
                                className="mx-auto h-fit w-fit rounded-full hover:bg-hoverish dark:hover:bg-dark-secondary ease-in duration-100 cursor-pointer p-3"
                                onClick={() => {
                                    window.history.back()
                                }}
                            >
                                <MdOutlineArrowBack className="text-2xl" />
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                            <span className="inline-block align-middle font-black text-xl">
                                {id ? 'Edit' : 'New'} contribution
                            </span>{' '}
                        </div>
                        <div className="grow"></div>
                        <div className="flex py-3 pr-4"><div
                            className="rounded-full bg-greenish hover:bg-dark-greenish transition ease-in duration-100 text-white px-6 py-2 cursor-pointer"
                            onClick={saveContribution}
                        >
                            Save
                        </div></div>
                    </div>
                    <div className="py-3">
                        <div className="px-4 text-xl text-black dark:text-white">
                            <span>Title</span>
                            <div>
                                <input
                                    type="text"
                                    maxLength={50}
                                    className="rounded bg-white dark:bg-dark-secondary dark:text-white dark:border-0 text-xl px-3 border border-gray-300 py-2 w-full mt-1 text-black"
                                    value={title}
                                    placeholder="Enter here the contribution title"
                                    onChange={onTitleChange}
                                    name="hey"
                                    id=""
                                />
                            </div>
                            {titleError && <div className="text-red-400 py-2 font-black">The title needs to be at least 10 characters long.</div>}
                        </div>
                    </div>
                    <div>
                        <div className="border-y border-gray-100 dark:border-dark-secondary">
                            <MDEditor
                                value={text}
                                onChange={setText}
                                height={600}
                            />
                            {textError && <div className="text-red-400 p-4 font-black">The text needs to be at least 100 characters long.</div>}
                            <div className="py-3 sm:py-0"></div>
                        </div>
                        <div className="p-2">
                            <span className="italic text-gray-600 dark:text-gray-400 ">
                                Use markdown language. To learn more about
                                markdown, click{' '}
                                <a
                                    href="https://www.markdownguide.org/getting-started/"
                                    className="underline"
                                >
                                    here
                                </a>
                                .
                            </span>
                        </div>
                        {fieldCategories && (
                            <div className="pt-3 pb-6">
                                <div className="px-4 text-2xl py-3">
                                    Categories
                                </div>
                                {categoryError && <div className="text-red-400 p-4 font-black">You need to choose at least one category.</div>}
                                <div className="px-3 flex flex-wrap gap-2 ">
                                    {categories.map((category: any) => {
                                        let included = fieldCategories.find(
                                            (cnt: { title: string }) =>
                                                cnt.title == category.title
                                        )
                                        return (
                                            <div
                                                className={`inline-flex rounded-full border border-gray-100 dark:border-dark-secondary ${
                                                    included
                                                        ? 'bg-greenish'
                                                        : ''
                                                } hover:bg-dark-greenish dark:hover:bg-dark-greenish hover:text-white dark:text-white w-fit pl-3 py-2 font-black text-sm cursor-pointer`}
                                                onClick={() => {
                                                    addOrRemoveCategory(
                                                        category
                                                    )
                                                }}
                                            >
                                                #{category.title}{' '}
                                                {included ? (
                                                    <div className="mx-2 border-l border-white px-2">
                                                        <MdClose className="mt-1 text-white dark:text-white" />
                                                    </div>
                                                ) : (
                                                    <div className="mr-3"></div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex px-4 py-6 ">
                        <div className="grow"></div>

                        
                    </div>
                </div>
            )
        } else {
            return (
                <>
                    <div
                        className="border-b  border-gray-100 dark:border-dark-secondary h-16 flex text-black dark:text-white sm:border-x
      sm:border-gray-100
      sm:dark:border-dark-secondary"
                    >
                        <div className="w-16 h-full items-center flex ">
                            <div
                                className="mx-auto h-fit w-fit rounded-full hover:bg-hoverish dark:hover:bg-dark-secondary ease-in duration-100 cursor-pointer p-3"
                                onClick={() => {
                                    window.history.back()
                                }}
                            >
                                <MdOutlineArrowBack className="text-2xl" />
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                            {' '}
                            <span className="inline-block align-middle font-black text-xl">
                                Go back
                            </span>{' '}
                        </div>
                        <div className="grow"></div>
                    </div>
                    <div className="flex w-full bg-white min-h-screen dark:bg-dark-primary sm:border-x sm:border-gray-100 sm:dark:border-dark-secondary">
                        <div className="mx-auto pt-16 my-16">
                            <div className="flex flex-col items-center">
                                <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 dark:text-white md:text-3xl">
                                    <span className="text-greenish">Oops!</span>{' '}
                                    You cannot edit this contribution.
                                </h6>
                                <p className="mb-8 text-center text-gray-500 dark:text-gray-300 md:text-lg">
                                    Why? Because this is not your contribution
                                    :)
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    } else {
        return (
            <div>
                <div className="border-b  border-gray-100 dark:border-dark-secondary h-16 flex ">
                    <div className="w-16 h-full items-center flex ">
                        <div
                            className="mx-auto h-fit w-fit rounded-full hover:bg-hoverish dark:hover:bg-dark-secondary ease-in duration-100 cursor-pointer p-3"
                            onClick={() => {
                                window.history.back()
                            }}
                        >
                            <MdOutlineArrowBack className="text-2xl" />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        {' '}
                        <span className="inline-block align-middle font-black text-xl">
                            {id ? 'Edit' : 'New'} contribution
                        </span>{' '}
                    </div>
                    <div className="grow"></div>
                    <div className="flex py-2"></div>
                </div>
                <div className="animate-pulse border-b border-gray-100 dark:border-dark-secondary py-6 px-3">
                    <div className="flex">
                        <div className="rounded-full p-6 bg-gray-200 dark:bg-dark-secondary"></div>
                        <div className="mt-1">
                            <div className="rounded-full w-40 ml-3 p-2 bg-gray-200 dark:bg-dark-secondary"></div>
                            <div className="rounded-full w-40 ml-3 mt-1 p-2 bg-gray-200 dark:bg-dark-secondary"></div>
                        </div>
                    </div>
                    <div className="pl-9">
                        <TextSkeleton lines={12} />
                    </div>
                </div>
            </div>
        )
    }
}

export default EditContribution
