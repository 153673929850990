import { FC } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { Forgot } from "../Forgot";
import Login from "../Login";
import SignUp from "../SignUp";

export const SessionModal = (props: {modalCallback: (state:any) => void,modal: any}) => {
    return (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none dark:text-white" onClick={() => props.modalCallback(undefined)}
          >
            <div className="my-0 sm:my-6 relative w-screen sm:w-fit sm:max-w-7xl sm:min-w-[500px]">
              {/*content*/}
              <div className="border-0 sm:rounded-lg shadow-lg  relative flex flex-col w-full bg-white  dark:bg-dark-primary outline-none focus:outline-none" 
              onClick={(e:React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                {/*header*/}
                <div className="flex items-start justify-between  rounded-t h-12 mb-10 border-b border-gray-100 dark:border-dark-tertiary">
                
                  <button
                    className="p-2 mr-auto bg-transparent border-0 text-black dark:text-white float-right text-3xl leading-none font-semibold outline-none focus:outline-none bg-transparent text-gray-300 hover:text-red-400 dark:hover:text-red-400 dark:text-white h-6 w-6 text-2xl block outline-none focus:outline-none"
                    onClick={() => props.modalCallback(undefined)}
                  >
                    <RiCloseCircleFill/>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto">
                  {props.modal == 'login' && <Login modalCallback={props.modalCallback}/>}
                  {props.modal == 'signup' && <SignUp modalCallback={props.modalCallback}/>}{props.modal == 'forgot' && <Forgot modalCallback={props.modalCallback}/>}
                  
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b">
                  <button
                    className="text-greenish background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:underline"
                    type="button"
                    onClick={() => props.modalCallback(undefined)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-75 fixed inset-0 z-40 bg-black" ></div>
        </>
    );
}
