import { Tab } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContributionsFeed } from "../components/contributions/ContributionsFeed";

import { Header } from "../components/delegates/Header";
import { Voters } from "../components/delegates/Voters";
import { Proposal } from "../components/proposal/Proposal";
import tabClass from "../components/navigation/Tabs";
import delegates from "../lib/api";

export const Delegate = () => {
    const params = useParams();
    const [delegate, setDelegate] = useState(undefined);
    const [voters, setVoters] = useState(undefined);
    const [delegateInfo, setDelegateInfo] = useState(undefined);
    useEffect(() => {
        delegates
                .api()
                .find(params.username)
                .then((dels: any) => {
                    setDelegate(dels);
                }).catch(() => {
                    window.location.href = '/404';
                });

                delegates.api().core.get("delegates/"+params.username+"/voters").then((voters) => {
                    setVoters(voters.body.data);
                });
                delegates.api().core.api("delegates").get(params.username).then((delegate) => {
                    setDelegateInfo(delegate.body.data);
                });

    }, []);
    if (delegate) {
    return (
        <div className="border-x border-gray-100 dark:border-dark-secondary w-full min-h-screen text-black dark:text-white max-w-5xl">
            <Header delegate={delegate}/>

            <Tab.Group defaultIndex={window.location.hash? (window.location.hash == '#contributions'? 1 : (window.location.hash == '#voters'? 2 : 0)) : 0}>
                <div className="mx-auto ">
                    <div className="w-100 border-y border-gray dark:border-dark-secondary overflow-x-scroll no-scrollbar">
                        <div className="w-full sm:w-2/4">
                            <Tab.List className="flex">
                                <Tab key="proposal" className={tabClass}>
                                    Proposal
                                </Tab>
                                <Tab key="contributions" className={tabClass}>
                                    <div className="flex space-x-3"> <span>Contributions</span> <span className="bg-greenish text-white dark:bg-dark-secondary text-sm px-3 rounded-full">{delegate.contribution_count}</span></div>
                                </Tab>
                                <Tab key="voters" className={tabClass}>
                                    <div className="flex space-x-3"> <span>Voters</span> <span className="bg-greenish text-white dark:bg-dark-secondary text-sm px-3 rounded-full">{voters? voters.length : '-'}</span></div>
                                </Tab>
                            </Tab.List>
                        </div>
                    </div>
                </div>
                {delegate && 
                <Tab.Panels>
                    <Tab.Panel key="proposal">
                            <Proposal username={params.username}/>
                    </Tab.Panel>
                    <Tab.Panel key="contributions">
                            <ContributionsFeed search={{username:delegate.username}}/>
                    </Tab.Panel>
                    <Tab.Panel key="voters">
                            <Voters delegateInfo={delegateInfo} voters={voters}/>
                    </Tab.Panel>
                </Tab.Panels>
                }
            </Tab.Group>
        </div>
    );}
    else {
        return (<></>)
    }
}
