import { Outlet } from 'react-router-dom'
import Menu from './components/navigation/Menu'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { HiUsers } from 'react-icons/hi'
import { MdArticle } from 'react-icons/md'
import { BiHomeCircle } from 'react-icons/bi'

function App() {
    return (
        <div className="App">
            <ReactNotifications />
            <Menu />
                <div className="min-h-screen">
                    <div className="sm:flex sm:mx-auto sm:max-w-7xl">
                        <div className="flex-none w-[250px] py-4 font-bold hidden sm:block">
                            <div className="sticky top-0">
                                <div
                                    className="hover:text-white hover:bg-greenish dark:hover:bg-dark-tertiary rounded-3xl m-2 dark:text-white text-2xl py-3 px-6 cursor-pointer w-fit flex ease-in duration-100"
                                    onClick={() =>
                                        (window.location.href = '/home')
                                    }
                                >
                                    <BiHomeCircle className="inline-block mt-1" />{' '}
                                    <div className="ml-2">Home</div>
                                </div>
                                <div
                                    className="hover:text-white hover:bg-greenish dark:hover:bg-dark-tertiary rounded-3xl m-2 dark:text-white text-2xl py-3 px-6 cursor-pointer w-fit flex ease-in duration-100"
                                    onClick={() =>
                                        (window.location.href = '/delegates')
                                    }
                                >
                                    <HiUsers className="inline-block mt-1" />{' '}
                                    <div className="ml-2">Delegates</div>
                                </div>
                                <div
                                    className="hover:text-white hover:bg-greenish dark:hover:bg-dark-tertiary rounded-3xl m-2 dark:text-white text-2xl py-2 px-6 cursor-pointer w-fit flex ease-in duration-100 items-center"
                                    onClick={() =>
                                        (window.location.href =
                                            '/contributions')
                                    }
                                >
                                    <MdArticle className="inline-flex " />{' '}
                                    <span className="mt-1">Contributions</span>
                                </div>
                                <div className="ml-5">
                                    {[
                                        'general',
                                        'delegates',
                                        'tools',
                                        'news',
                                        'media',
                                        'games',
                                        'development',
                                        'plugins',
                                        'marketing',
                                        'security',
                                        'bots',
                                        'nodes',
                                        'exchange',
                                        'services',
                                    ].map((title) => (
                                        <div
                                            className="hover:text-white hover:bg-greenish dark:hover:bg-dark-tertiary rounded-3xl mx-4 my-2 dark:text-white text-lg px-3 py-1 cursor-pointer ease-in duration-100"
                                            onClick={() => {
                                                window.location.href =
                                                    '/contributions/' + title
                                            }}
                                        >
                                            # {title}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="sm:grow">
                            <Outlet />
                        </div>
                    </div>
                </div>

                <div className="border-t-2  border-gray-100 dark:border-dark-secondary text-center text-white">
                    <div className="max-w-7xl mx-auto py-6 text-black dark:text-white">
                        2022 Solar Delegates |{' '}
                        <a href="https://solar.org/" className="text-greenish">
                            Solar
                        </a>{' '}
                        | Made by delegate{' '}
                        <a href="https://delegates.solar.org/delegates/leitesv" className="text-greenish">
                            leitesv
                        </a>
                    </div>
                </div>

                <div className="sticky bottom-0 sm:hidden py-2 bg-primary dark:bg-dark-primary border-t border-gray-100 dark:border-dark-secondary flex space-x-2 z-50">
                    <div className="w-fit flex mx-auto">
                        <div
                            className="p-2 rounded-full text-black dark:text-white hover:bg-hoverish dark:hover:bg-dark-hoverish"
                            onClick={() => (window.location.href = '/home')}
                        >
                            <BiHomeCircle className=" my-auto text-3xl" />
                        </div>
                        <div
                            className="p-2 rounded-full text-black dark:text-white hover:bg-hoverish dark:hover:bg-dark-hoverish"
                            onClick={() =>
                                (window.location.href = '/delegates')
                            }
                        >
                            <HiUsers className=" my-auto text-3xl" />
                        </div>
                        <div
                            className="p-2 rounded-full text-black dark:text-white hover:bg-hoverish dark:hover:bg-dark-hoverish"
                            onClick={() =>
                                (window.location.href = '/contributions')
                            }
                        >
                            <MdArticle className=" my-auto text-4xl" />
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default App
