export const EditProfileButton = (props: {delegate:any}) => {
    return (
        <div className="rounded-full inline-flex border dark:border-dark-secondary  hover:dark:bg-dark-hoverish cursor-pointer p-2 dark:text-white hover:bg-dark-greenish w-fit flex  items-center h-fit space-x-2 hover:bg-hoverish dark:hover:bg-dark-hoverish mx-2" onClick={() => {window.location.href = '/profile'}}>
        
            <span className="font-black px-2">Edit Profile</span>
        </div>
    );
}

export const EditProposalButton = (props: {delegate:any}) => {
    return (
        <div className="rounded-full inline-flex border dark:border-dark-secondary  hover:dark:bg-dark-hoverish cursor-pointer p-2 dark:text-white hover:bg-dark-greenish w-fit flex  items-center h-fit space-x-2 hover:bg-hoverish dark:hover:bg-dark-hoverish mx-2" onClick={() => {window.location.href = '/profile/proposal/edit'}}>
        
            <span className="font-black px-2">Edit Proposal</span>
        </div>
    );
}
