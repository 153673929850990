import { BsClock, BsFillCircleFill } from "react-icons/bs";
import moment from "moment";
import { Avatar } from '../delegates/Avatar'
export const Author = (props:{contribution:any, className?:string}) => {
  const contribution = props.contribution;
  const delegate = props.contribution.delegate;
  return (
    <div className="flex my-1 ">
    <Avatar delegate={delegate} className="h-12"/>
    <div className="ml-3">
      <a
        href={`/delegates/${delegate.username}`}
        className={`text-xl font-bold hover:underline hover:cursor-pointer ${props.className? props.className : ''}`}
      >
        {delegate.username}<span className="text-gray-400 text-base">#{delegate.rank}</span> <BsFillCircleFill className={`text-sm ${delegate.rank < 54? 'text-green-400' : 'text-yellow-400'} inline-block pb-1`} />
      </a>
      <div>
      <a href='#' className="text-gray-600 dark:text-gray-400 text-sm hover:underline hover:cursor-pointer" title={`${moment(contribution.created_at).format("DD/MM/YYYY hh:mm:ss")}`}>
      <BsClock className="inline-block text-base pb-1"/> Posted {moment(contribution.created_at).fromNow()}
      </a>
      </div>
    </div>
  </div>
  );
}


export default Author