import { useParams } from "react-router-dom";
import ContributionsFeed from "../components/contributions/ContributionsFeed";

export const Contributions = () => {
    const params = useParams();
    return (
        <div className="text-black dark:text-white max-w-5xl border-x border-gray-100 dark:border-dark-secondary min-h-screen">
            <div className="py-6 border-b border-gray-100 dark:border-dark-secondary font-black text-4xl px-4">
            {params.category? `#${params.category}` : 'All contributions'}
            </div>
            <div className="flex  border-b border-gray-100 dark:border-dark-secondary px-2 space-x-2 overflow-x-scroll no-scrollbar sm:hidden">
            {[
                    'general',
                    'delegates',
                    'tools',
                    'news',
                    'media',
                    'games',
                    'development',
                    'plugins',
                    'marketing',
                    'security',
                    'bots',
                    'nodes',
                    'exchange',
                    'services'
                  ].map((title) => (
                    <div className="inline-flex hover:text-white hover:bg-greenish dark:hover:bg-dark-tertiary rounded-3xl my-2 border border-gray-100 dark:border-dark-secondary dark:text-white px-2 py-1 cursor-pointer ease-in duration-100" onClick={() => { window.location.href = '/contributions/' + title }}> <span>#{title}</span></div>
                  ))}
            </div>
            {params.category? <ContributionsFeed search={{categories:[params.category]}} limit={50}/> : <ContributionsFeed limit={50}/>}
            
        </div>
    );
}

export default Contributions;