import { useEffect, useState } from "react";
import { ContributionsSkeleton } from "../skeletons/ContributionsSkeleton";
import { AiOutlineReload } from 'react-icons/ai';
import delegates from "../../lib/api";
import { Contribution } from "./Contribution";
export const ContributionsFeed = (props: { search?: {username?: string, categories?:string[]}, limit?:number}) => {
    const [contributions, setContributions] = useState(undefined);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    useEffect(() => {
        loadContributions();
    },[]);

    const loadContributions = () => {
        delegates
            .api()
            .contributions(props.search, 1, props.limit? props.limit : 10)
            .then((contribs: any) => {
                if (contribs.error) {
                    setError(true);
                } else {
                    setCurrentPage(contribs.currentPage)
                    setShowLoadMore (contribs.totalPages > contribs.currentPage)
                    setContributions(contribs.data);
                }
            }).catch(() => {
                setError(true);
            });
    }

    const loadMore = () => {
        delegates
        .api()
        .contributions(props.search, currentPage+1, props.limit? props.limit : 10)
        .then((contribs: any) => {
            if (contribs.error) {
                setError(true);
            } else {
                setShowLoadMore (contribs.totalPages > contribs.currentPage);
                setCurrentPage(contribs.currentPage)
                console.log(contribs.currentPage);
                setContributions(contributions.concat(contribs.data));
            }
        }).catch(() => {
            setError(true);
        });
    }
   
    if (error) {
        return (
            <>
                <div className="p-6 text-center font-black text-lg">
                    There was an error trying to retrieve the contributions.
                </div>
                <div className="mx-auto w-fit rounded-full border border-gray-100 dark:border-dark-secondary py-3 px-6 hover:bg-hoverish dark:hover:bg-dark-secondary cursor-pointer flex items-center" onClick={() => {setError(false);loadContributions()}}>
                    <AiOutlineReload className="inline-flex text-xl" /> 
                    <span className="inline-flex mx-2">Try again</span>
                </div>
            </>

        )
    } else {
        if (contributions) {
            if (contributions.length > 0) {
                return (
                <>
                {contributions.map((contribution:any) => (
                    <Contribution contribution={contribution} />
                ))}
                {showLoadMore && 
                <div className="text-center text-greenish dark:bg-dark-primary h-14 cursor-pointer py-4 dark:hover:bg-dark-tertiary font-black" onClick={loadMore}>
                Load more
                </div>
                }
                </>
                )
            } else {
                return (
                    <div className=" p-3 text-center font-black text-2xl"><div className="rounded border text-gray-400 mx-auto py-8 border-gray-100 dark:border-dark-secondary">There are no contributions yet!</div></div>
                );
            }
        } else {
            return <ContributionsSkeleton />
        }
    }
}

export default ContributionsFeed;