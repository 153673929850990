import React from "react";
import Downshift from "downshift";


export default function DelegateSearchBar(props:any) {
    const usernames : Array<string> = props.usernames;
    const handleUsername = props.handleUsername;
  return (
    <Downshift
      onChange={selection =>{
          handleUsername(selection);
      }
      }
      itemToString={item => (item ? item : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex
      }) => (
        <div className="">
          <div className="w-[300px] mt-6">
            <input
              placeholder="Search your username"
              className="border border-gray-100 dark:border-dark-secondary rounded w-full py-2 px-3 text-black dark:text-white focus:bg-gray-100 bg-white placeholder:text-gray-400 focus:placeholder:text-gray-600 dark:placeholder:text-gray-600 dark:focus:placeholder:text-gray-400 dark:bg-dark-primary
              dark:focus:bg-dark-tertiary focus:outline-none ease-in duration-100 outline-none"
              {...getInputProps()}
            />
            <ul className="rounded bg-gray-100 dark:bg-dark-secondary text-black dark:text-white max-h-20 overflow-y-scroll" {...getMenuProps()}>
              {isOpen
                ? usernames
                    .filter(
                      item =>
                        !inputValue ||
                        item.toLowerCase().includes(inputValue.toLowerCase())
                    )
                    .map((item, index) => (
                      <li
                        {...getItemProps({
                          key: item,
                          index,
                          item,
                          className: `py-2 px-2 ${
                            highlightedIndex === index
                              ? "bg-hoverish dark:bg-dark-hoverish text-black dark:text-white cursor-pointer"
                              : "bg-secondary dark:bg-dark-secondary text-black dark:text-white cursor-pointer"
                          }`
                        })}
                      >
                        {item}
                      </li>
                    ))
                : null}
            </ul>
          </div>
        </div>
      )}
    </Downshift>
  );
}
