import { BiNetworkChart } from "react-icons/bi"

export const Testnet = (props: {delegate:any}) => {
    if (props.delegate.testnet_username && props.delegate.testnet_username.length > 0) {
        return (
            <div className="inline-block flex items-center">
              <BiNetworkChart className="text-xl text-white mx-1 text-green-600 dark:text-green-400 inline-flex" />
              <a className="text-green-600 dark:text-green-400 pb-1 font-black">
                On testnet
              </a>
            </div>
          )
    }
    else {
        return (
            <></>
          )
      } 
  }